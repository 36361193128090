import UserFactory from "@/models/factories/UserFactory";

const actions = {
  setDataUser({ commit }, payload = {}) {
    const user = UserFactory.create(payload);
    commit("SET_DATA_USER", user);
  },
};

export default actions;
