import { inject, injectable, TYPES } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { GeoLocalizationDTO } from "@/arch/@core/application/DTOs/GeoLocalizationDTO";
import { ContractDataGateway } from "@/arch/@core/application/protocols/ContractDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class ContractGateway implements ContractDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  acceptContract(
    proposalId: number | string,
    paramsDto: GeoLocalizationDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/contract/accept`,
      body: paramsDto.toRequest(),
    });
  }

  getContract(proposalId: number | string): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/contract`,
    });
  }

  createContract(proposalId: number | string): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/contract`,
    });
  }

  getContractView(ticketId: number | string): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/admin/contract/${ticketId}`,
    });
  }

  getDigitalAccountConsentContract(proposalId: number | string): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/documents/contrato_conta_digital`,
      params: {
        buffered: true,
      },
    });
  }

  createDigitalAccountConsentContract(proposalId: number | string): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/contract-digital-account`,
    });
  }

  signDigitalAccountConsentContract(
    proposalId: number | string,
    paramsDto: GeoLocalizationDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/contract-digital-account/accept`,
      body: {
        authorize_consignment: true,
        ...paramsDto.toRequest(),
      },
    });
  }
}
