import { IOnboardAPI } from "@/services/api/types/OnboardAPI";
import AbstractApi, { AbstractApiProps } from "@/services/api/AbstractApi";
import ProposalResource from "@/resources/onboard/ProposalResource";
import UsersResource from "@/resources/onboard/UserResource";
import StatusResource from "@/resources/onboard/StatusResource";
import ModalityResource from "@/resources/onboard/ModalityResource";
import LoanTypeResource from "@/resources/onboard/LoanTypeResource";
import LoanValueResource from "@/resources/onboard/LoanValueResource";
import LoanInstallmentResource from "@/resources/onboard/LoanInstallmentResource";
import CovenantResource from "@/resources/onboard/CovenantResource";
import SimulationResource from "@/resources/onboard/SimulationResource";
import LoanReasonResource from "@/resources/onboard/LoanReasonResource";
import OccupationsUserResource from "@/resources/onboard/OccupationsUserResource";
import OccupationsProposalResource from "@/resources/onboard/OccupationsProposalResource";
import TemplateResource from "@/resources/onboard/TemplateResource";
import AgreementResource from "@/resources/onboard/AgreementResource";
import StateResource from "@/resources/onboard/StateResource";
import CityResource from "@/resources/onboard/CityResource";
import AdditionalInformationResource from "@/resources/onboard/AdditionalInformationResource";
import AddressResource from "@/resources/onboard/AddressResource";
import PersonalReferenceResource from "@/resources/onboard/PersonalReferenceResource";
import BankLoanConfigurationResource from "@/resources/onboard/BankLoanConfigurationResource";
import LoanCategoryResource from "@/resources/onboard/LoanCategoryResource";
import PoliticallyExposedResource from "@/resources/onboard/PoliticallyExposedResource";
import BankResource from "@/resources/onboard/BankResource";
import DocumentResource from "@/resources/onboard/DocumentResource";
import QuizResource from "@/resources/onboard/QuizResource";
import SMSResource from "@/resources/onboard/SMSResource";
import ContractResource from "@/resources/onboard/ContractResource";
import RefinancingContractResource from "@/resources/onboard/RefinancingContractResource";
import CompanyResource from "@/resources/onboard/CompanyResource";
import RoleResource from "@/resources/onboard/RoleResource";
import ComplianceResource from "@/resources/onboard/ComplianceResource";
import SalesResource from "@/resources/onboard/SalesResource";
import PendencyResource from "@/resources/onboard/PendencyResource";
import UnicoResource from "@/resources/onboard/UnicoResource";
import CancelProposalResource from "@/resources/onboard/CancelProposalResource";
import TotvsResource from "@/resources/onboard/TotvsResource";
import BlufinResource from "@/resources/onboard/BlufinResource";
import SpouseResource from "@/resources/onboard/SpouseResource";
import RedirectResource from "@/resources/onboard/RedirectResource";
import TermsOfUseResource from "@/resources/onboard/TermsOfUseResource";
import AuthResource from "@/resources/onboard/AuthResource";
import EmailResource from "@/resources/onboard/EmailResource";
import PluginsResource from "@/resources/onboard/PluginsResource";
import BillingResource from "@/resources/onboard/BillingResource";
import InterestPlansResource from "@/resources/onboard/InterestPlansResource";
import ProfessionsResource from "@/resources/onboard/ProfessionsResource";
import ModalRuleDefinitionResource from "@/resources/onboard/ModalRuleDefinitionResource";

class OnboardApi extends AbstractApi implements IOnboardAPI {
  constructor(props: AbstractApiProps) {
    super(props);
    this.resources = {
      auth: new AuthResource(this),
      email: new EmailResource(this),
      proposal: new ProposalResource(this),
      user: new UsersResource(this),
      status: new StatusResource(this),
      modality: new ModalityResource(this),
      loanType: new LoanTypeResource(this),
      loanValue: new LoanValueResource(this),
      loanReason: new LoanReasonResource(this),
      loanInstallment: new LoanInstallmentResource(this),
      covenant: new CovenantResource(this),
      simulation: new SimulationResource(this),
      occupationUser: new OccupationsUserResource(this),
      occupationProposal: new OccupationsProposalResource(this),
      template: new TemplateResource(this),
      agreement: new AgreementResource(this),
      state: new StateResource(this),
      city: new CityResource(this),
      additionalInformation: new AdditionalInformationResource(this),
      address: new AddressResource(this),
      personalReference: new PersonalReferenceResource(this),
      bankLoanConfiguration: new BankLoanConfigurationResource(this),
      loanCategory: new LoanCategoryResource(this),
      politicallyExposed: new PoliticallyExposedResource(this),
      bank: new BankResource(this),
      document: new DocumentResource(this),
      quiz: new QuizResource(this),
      sms: new SMSResource(this),
      contract: new ContractResource(this),
      refinancingContract: new RefinancingContractResource(this),
      company: new CompanyResource(this),
      role: new RoleResource(this),
      compliance: new ComplianceResource(this),
      sales: new SalesResource(this),
      pendencies: new PendencyResource(this),
      unico: new UnicoResource(this),
      cancelProposal: new CancelProposalResource(this),
      totvs: new TotvsResource(this),
      blufin: new BlufinResource(this),
      spouse: new SpouseResource(this),
      redirect: new RedirectResource(this),
      termsOfUse: new TermsOfUseResource(this),
      plugins: new PluginsResource(this),
      billing: new BillingResource(this),
      interestPlans: new InterestPlansResource(this),
      professions: new ProfessionsResource(this),
      modalRuleDefinition: new ModalRuleDefinitionResource(this)
    };
  }
}

export default OnboardApi;
