import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class PersonalReferenceResource extends AbstractOnboardResource {
  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async getPersonalReferencesProposal({ proposalId }) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/personal-references`,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @param name
   * @param phone
   * @param relationship
   * @return {Promise<ResourceReturnModel>}
   */
  async setPersonalReferencesProposalUser({ proposalId, name, phone, relationship }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/personal-references`,
        payload: { name, phone, relationship },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @param name
   * @param phone
   * @param relationship
   * @param personalReferenceId
   * @return {Promise<ResourceReturnModel>}
   */
  async updatePersonalReferencesProposalUser({
    proposalId,
    name,
    phone,
    relationship,
    personalReferenceId,
  }) {
    try {
      const response = await this.api.put({
        route: `/proposals/${proposalId}/personal-references/${personalReferenceId}`,
        payload: { name, phone, relationship },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }
}
