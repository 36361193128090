import {AxiosResponse} from "axios";

export default class ResourceReturnModel<T> {
  public error: boolean;
  public data: T;
  public responseObject: AxiosResponse;

  constructor({ error, data, responseObject } ) {
    this.error = error;
    this.data = data;
    this.responseObject = responseObject;
  }
}
