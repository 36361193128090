import { TemplateState } from "@/store/modules/template/state";
import { TemplateElement } from "@/types/models/TemplateElement";

const mutations = {
  setCheckBox(state: TemplateState, payload: TemplateElement[]) {
    const checkBoxes = payload.filter((item) => item.element_type.includes("check_box"));
    if (checkBoxes.length) {
      state.checkbox.hasCheckBoxes = true;
      state.checkbox.values = checkBoxes;
    } else {
      state.checkbox.hasCheckBoxes = false;
    }
  },
};

export default mutations;
