import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { TermsOfUseDataGateway } from "@/arch/@core/application/protocols/TermsOfUseDataGateway";
import { TermsOfUseDTO } from "@/arch/@core/application/DTOs/TermOfUseDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class TermsOfUseGateway implements TermsOfUseDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getTerms(code: TermsOfUseDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/public/brands/pages",
      params: { code },
    });
  }
}
