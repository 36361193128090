const mutations = {
  SET_DATA_PROPOSAL(state, payload) {
    state.proposal = payload;
  },
  /**
   *
   * @param state
   * @param {BankLoanConfigStatusModel} payload
   * @constructor
   */
  SET_STATUS_PROPOSAL(state, payload) {
    state.bankLoanConfigStatus = payload;
  },
};

export default mutations;
