import AbstractFactory from "@/models/factories/AbstractFactory";
import CovenantModel from "@/models/CovenantModel";

class CovenantFactory extends AbstractFactory {
  /**
   * @param {object} object
   * @param {object} options
   * @returns {CovenantModel}
   */
  create({ object = {}, options = {} } = {}) {
    return new CovenantModel(object);
  }
}

export default new CovenantFactory();
