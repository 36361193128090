import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import ResourceReturnModel from "@/models/ResourceReturnModel";

type HttpResponse<Model> = {
  response: Model;
  statusCode: number;
  message: string;
};

type Simulation = {
  id: number;
  bank_id: number;
  bank: {
    id: number;
    name: string;
    short_name?: null;
    number: string;
    code: string;
  };
  partner_id: number;
  partner: {
    id: number;
    name: string;
    code: string;
    url_domain: string;
  };
  modality_id: number;
  modality: {
    id: number;
    name: string;
    code: string;
    ordering?: null;
    description: string;
    help_text?: null;
    loan_feature_id: number;
  };
};

type SimulationDataDTO = {
  min_loan_amount: number;
  max_loan_amount: number;
};

type LimitsDTO = {
  min: number;
  max: number;
};

type InsuranceDto = {
  minLoanAmount: number;
  maxLoanAmount: number;
};

export namespace Simulation {
  export type PostParams = {
    proposal_id?: number | string;
    bank_id?: number;
    modality_id?: number;
  };
  export type PostBody = {
    requested_amount?: number;
    available_amount?: number;
    installment_value?: number;
    requested_installments?: number[] | number;
    first_payment_date?: string;
  };
  export type PostResponse = Modality[];
  export type Modality = {
    modality_id?: number;
    modality_name: string;
    simulations: Data[];
  };
  export type Data = {
    number_installments: number;
    installment_value: number;
    loan_value: number;
    gross_value: number;
    total_iof?: number;
    nominal_cet_month?: number;
    nominal_cet_year?: number;
    nominal_rate_month?: number;
    nominal_rate_year?: number;
    base_date?: string | Date;
    main_value?: number;
    irr_rate?: number;
    deposit_date?: Date;
    first_payment_date?: Date;
    last_payment_date?: Date;
    tax_value?: number;
    iof_rate?: number;
    interest_value?: number;
    iof_complement_rate?: number;
    financing_rate?: number;
    installments?: Installments[];
    total_tac: number;
    additional_fees: number;
  };

  type Installments = {
    installment: number;
    due_date: string | Date;
    installment_value?: number;
    balance?: number;
    interest_rate_value?: number;
    amortization?: number;
    iof_value?: number;
  };

  export type Model = Simulation;
}

export type SimulationPlans = {
  installments: number;
  max_loan_amount: number;
  min_loan_amount: number;
};

export default class SimulationResource extends AbstractOnboardResource {
  /**
   *
   * @param covenantId
   * @param loanTypeId
   * @param requiredInstallments
   * @param requiredValue
   * @param roleId
   * @param modalityId
   * @param loanCategoryId
   * @return {Promise<ResourceReturnModel>}
   */
  async postSimulationBasic({
    covenantId,
    loanTypeId,
    requiredInstallments,
    requiredValue,
    installmentValue,
    roleId,
    modalityId,
    loanCategoryId,
  }: any) {
    try {
      const response = await this.api.post({
        route: "/public/simulation/basic",
        payload: {
          covenant_id: covenantId,
          loan_type_id: loanTypeId,
          required_installments: requiredInstallments,
          required_value: requiredValue,
          installment_value: installmentValue,
          role_id: roleId,
          loan_category_id: loanCategoryId,
          modality_id: modalityId,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          data: response?.data?.response || {},
          error: false,
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          data: {},
          error: true,
          responseObject: e,
        },
      });
    }
  }

  async getPartnersSimulationList(): Promise<Simulation[]> {
    const httpRes = await this.api.get({ route: "/partners/simulation" });
    const { response } = httpRes.data as HttpResponse<Simulation[]>;
    return response;
  }

  async doPartnersSimulation(
    paramsData: Simulation.PostParams,
    bodyData?: Simulation.PostBody
  ): Promise<ResourceReturnModel> {
    try {
      const httpResponse = await this.api.post({
        route: "/partners/simulation",
        params: paramsData,
        payload: bodyData,
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: (httpResponse?.data?.response as Simulation.PostResponse) || {},
          responseObject: httpResponse,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: [],
          responseObject: e,
        },
      });
    }
  }

  async getSimulations(proposalId: string | number) {
    try {
      const response = await this.api.get({
        route: `/simulation/limits`,
        params: {
          proposal_id: proposalId,
        },
      });

      if (!response?.data?.response) return null;

      return response.data.response as SimulationDataDTO;
    } catch (e) {
      return null;
    }
  }

  async getLimitsV2() {
    try {
      const response = await this.api.get({ route: `/public/loan-values` });

      if (!response?.data?.response) return null;

      return response.data.response as LimitsDTO;
    } catch (e) {
      return null;
    }
  }

  async getInsuranceValues(proposalId: string | number) {
    try {
      const response = await this.api.get({
        route: `/insurance-values`,
        params: {
          proposal_id: proposalId,
        },
      });

      if (!response?.data?.response) return null;

      return response.data.response as InsuranceDto;
    } catch (e) {
      return null;
    }
  }

  async getSimulationPaymentDueDateRange(
    proposalId: string | number
  ): Promise<{ range: boolean; dates: string[] }> {
    try {
      const response = await this.api.get({
        route: `/simulation/payment-due-date-range`,
        params: {
          proposal_id: proposalId,
        },
      });

      if (!response?.data?.response) return null;

      return response.data.response;
    } catch (e) {
      return null;
    }
  }

  async getSimulationPlans(proposalId: string | number): Promise<SimulationPlans[]> {
    try {
      const response = await this.api.get({
        route: `/simulation/plans`,
        params: {
          proposal_id: proposalId,
        },
      });

      if (!response?.data?.response) return null;

      return response.data.response;
    } catch (e) {
      return null;
    }
  }
}
