import AbstractFactory from "@/models/factories/AbstractFactory";
import StatusGroupModel from "@/models/StatusGroupModel";

class StatusGroupFactory extends AbstractFactory {
  /**
   *
   * @param {object} object
   * @param {object} options
   * @return {StatusGroupModel}
   */
  create({ object = {}, options = {} } = {}) {
    return new StatusGroupModel({
      order: null,
      code: null,
      description: null,
      id: null,
      checkpoint_id: null,
      title: null,
      ...object,
    });
  }
}

export default new StatusGroupFactory();
