import {
  GenericJSONBData,
  IVaryingOriginDataDate,
  IVaryingOriginDataString,
} from "@/types/models/utils/typedefs";

import { IUserModel } from "@/types/models/User";

import { IRoleModel } from "@/types/models/Role";
import { IOccupationModel } from "@/types/models/Occupation";

import { IOccupationFinancialInformation } from "@/types/proposal/simulation/occupationFinancialInformation";
import { ICovenantModel } from "@/types/models/Covenant";

export default class OccupationModel {
  public id: number;
  public user_id: number;
  public user: IUserModel;
  public covenant_id: number;
  public covenant: ICovenantModel;
  public role_id: number;
  public role: IRoleModel;
  public registration_number: IVaryingOriginDataString;
  public name: IVaryingOriginDataString;
  public administration_type: GenericJSONBData<number>;
  public admission_date: IVaryingOriginDataDate;
  public department: IVaryingOriginDataString;
  public covenant_password: IVaryingOriginDataString;
  public permanence_time: string;
  public financial_information: IOccupationFinancialInformation;

  constructor({
    id,
    user_id,
    user,
    covenant_id,
    covenant,
    role_id,
    role,
    registration_number,
    permanence_time,
    name,
    administration_type,
    admission_date,
    department,
    covenant_password,
    financial_information,
  }: IOccupationModel) {
    this.id = id;
    this.user_id = user_id;
    this.covenant_id = covenant_id;
    this.role_id = role_id;
    this.registration_number = registration_number;
    this.permanence_time = permanence_time;
    this.name = name;
    this.administration_type = administration_type;
    this.admission_date = admission_date;
    this.department = department;
    this.covenant_password = covenant_password;
    this.user = user;
    this.covenant = covenant;
    this.role = role;
    this.financial_information = financial_information;
  }
}
