import state from "@/store/modules/dashboard/state";
import actions from "@/store/modules/dashboard/actions";
import mutations from "@/store/modules/dashboard/mutations";
import getters from "@/store/modules/dashboard/getters";
export const module = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
