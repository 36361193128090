import AbstractFactory from "@/models/factories/AbstractFactory";
import ResourceReturnModel from "@/models/ResourceReturnModel";

class ResourceReturnFactory extends AbstractFactory {

  create<T>({ object = {}, options = {} } = {}) {
    return new ResourceReturnModel<T>({
      error: true,
      data: {},
      responseObject: {},
      ...object,
    });
  }
}

export default new ResourceReturnFactory();
