import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import { UnicoSuccessPictureResponse } from "@/components/unico-reader/UnicoReaderController";

export default class UnicoResource extends AbstractOnboardResource {
  async sendUnicoPicture(proposalId: number, { base64, encrypted }: UnicoSuccessPictureResponse) {
    const payload = new FormData();

    payload.append("base64", base64);
    payload.append("encrypted", encrypted);

    return this.api.post({
      route: `/proposals/${proposalId}/liveness`,
      payload,
    });
  }
}
