import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class BankResource extends AbstractOnboardResource {
  /**
   *
   * @return {Promise<ResourceReturnModel>}
   */
  async getBanks(proposalId) {
    try {
      const response = await this.api.get({
        route: "/public/banks",
        params: {
          proposal_id: proposalId,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }

  /**
   *
   * @param account_number
   * @param account_type
   * @param agency
   * @param bank_id
   * @return {Promise<ResourceReturnModel>}
   */
  async usersBankAccounts({ account_number, account_type, agency, bank_id }) {
    try {
      const response = await this.api.post({
        route: "/users/bank-accounts",
        payload: { account_number, account_type, agency, bank_id },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }

  /**
   *
   * @param account_number
   * @param account_type
   * @param agency
   * @param bank_id
   * @param userId
   * @return {Promise<ResourceReturnModel>}
   */
  async adminCreate({ account_number, account_type, agency, bank_id, userId }) {
    try {
      const response = await this.api.post({
        route: `/users/${userId}/bank-accounts`,
        payload: {
          account_number,
          account_type,
          agency,
          bank_id,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response.data.response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async getProposalBankAccount({ proposalId }) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/bank-account` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param account_number
   * @param account_type
   * @param agency
   * @param bank_id
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async updateProposalBankAccount({ account_number, account_type, agency, bank_id, proposalId }) {
    try {
      const response = await this.api.put({
        route: `/proposals/${proposalId}/bank-accounts`,
        payload: { account_number, account_type, agency, bank_id },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param account_number
   * @param account_type
   * @param agency
   * @param bank_id
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async createProposalBankAccount({ account_number, account_type, agency, bank_id, proposalId }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/bank-accounts`,
        payload: { account_number, account_type, agency, bank_id },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param payload
   * @param proposalId
   * @param shouldUpdate
   * @return {Promise<ResourceReturnModel>}
   */
  async createOrUpdateProposalBankAccount({ payload, proposalId, shouldUpdate }) {
    if (shouldUpdate) {
      return await this.updateProposalBankAccount({ ...payload, proposalId });
    }
    return await this.createProposalBankAccount({ ...payload, proposalId });
  }
}
