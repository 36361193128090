import { statusGroupState } from "@/store/modules/statusGroup/state";

const mutations = {
  hideCovenantAndValues(state: statusGroupState, payload) {
    const valores_e_dados_index = 1;
    state.admin[valores_e_dados_index].status_group.hidden = payload;
  },
  setBorrower(state: statusGroupState, payload: any) {
    state.borrower = payload;
  },
};

export default mutations;
