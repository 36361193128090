const state = {
  currentGlobalError: {
    status: 0,
    type: "",
    title: "",
    message: "",
  },
};
export type GlobalErrorState = typeof state;
export type GlobalErrorPayload = typeof state.currentGlobalError;

export default state;
