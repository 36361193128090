import state from "@/store/modules/template/state";
import actions from "@/store/modules/template/actions";
import mutations from "@/store/modules/template/mutations";

export const module = {
  namespaced: true,
  state,
  actions,
  mutations,
};
