import axios, { AxiosInstance } from "axios";
import middlewares from "@/middlewares";
import {
  deleteParams,
  getParams,
  postParams,
  putParams,
  sendRequestParams,
} from "@/services/api/types/AbstractAPI";
import { Vue } from "vue-property-decorator";
import store from "@/store";
import { IBrand } from "@/types/whitelabel";
import dinheirow from "@/whitelabel/labels/dinheirow";
import { DefaultHeaders } from "@/arch/@core/gateway/HttpClient";

export interface AbstractApiProps {
  baseURL: string;
  brand: IBrand;
}

interface ResponseError {
  response: {
    status: number;
    data: {
      message: string;
      error_title: string;
    };
  };
}

export default class AbstractApi extends Vue {
  public httpClient: AxiosInstance;
  public resources: any;
  private brand: IBrand = dinheirow;

  protected constructor({ baseURL, brand }: AbstractApiProps) {
    super();
    this.httpClient = axios.create({
      baseURL,
    });

    this.brand = brand;

    this.resources = {};
  }

  protected defaultHeaders: DefaultHeaders = {
    "Bank-Origin": this.brand.bankOrigin,
    "Partner-Code": this.brand.partnerCode,
  };

  async get({ route, params = {}, headers = {} }: getParams) {
    return this.sendRequest({
      method: "GET",
      route,
      params,
      headers: { ...this.defaultHeaders, ...headers },
    });
  }

  async post({ route, payload, params = {}, headers = {}, config = {} }: postParams) {
    return this.sendRequest({
      method: "POST",
      route,
      payload,
      params,
      headers: { ...this.defaultHeaders, ...headers },
      config,
    });
  }

  async delete({ route, payload, params = {}, headers = {} }: deleteParams) {
    return this.sendRequest({
      method: "DELETE",
      route,
      payload,
      params,
      headers: { ...this.defaultHeaders, ...headers },
    });
  }

  async put({ route, payload, params = {}, headers = {} }: putParams) {
    return this.sendRequest({
      method: "PUT",
      route,
      payload,
      params,
      headers: { ...this.defaultHeaders, ...headers },
    });
  }

  setDefaultHeaders(defaultHeaders: DefaultHeaders) {
    this.defaultHeaders = defaultHeaders;
  }

  setInterceptors(router: any) {
    this.httpClient.interceptors.request.use(async (request) => {
      middlewares.requestHandlers(request, router);
      return request;
    });

    this.httpClient.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: ResponseError) => {
        await store.dispatch("chooseGlobalError", {
          status: error.response.status,
          message: error.response.data.message,
          title: error.response.data?.error_title,
        });
        throw error.response;
      }
    );
  }

  private async sendRequest({
    method,
    route,
    payload,
    params,
    headers,
    config,
  }: sendRequestParams) {
    const promise = this.httpClient({
      method,
      url: route,
      data: payload,
      params,
      headers,
      ...config,
    });
    try {
      await store.dispatch("isLoading", true);
      return await promise;
    } finally {
      await store.dispatch("isLoading", false);
    }
  }
}
