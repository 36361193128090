import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class LoanReasonResource extends AbstractOnboardResource {
  /**
   *
   * @return {Promise<ResourceReturnModel>}
   */
  async getLoanReasons() {
    try {
      const response = await this.api.get({ route: "/public/loan-reasons" });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response || [],
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: [],
          responseObject: e,
        },
      });
    }
  }
}
