import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export interface CancelCategory {
  id: number;
  description: string;
}

export interface CancelReason {
  id: number;
  reason: string;
}

export interface CancelCategoryReason {
  id: number;
  description?: string;
  cancel_reasons: CancelReason[];
}

export default class CancelProposalResource extends AbstractOnboardResource {
  async cancel(
    proposalId: number,
    cancel_type_id: number,
    cancel_reasons: number[],
    is_internal = true
  ) {
    return await this.api.post({
      route: `proposals/${proposalId}/status/cancel`,

      payload: {
        cancel_type_id,
        cancel_reasons,
        is_internal,
      },
    });
  }

  async findCancelCategoryReasons(): Promise<CancelCategoryReason[]> {
    const response = await this.api.get({ route: "/cancel-type-reasons" });
    return response.data.response;
  }
}
