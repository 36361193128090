import AbstractFactory from "@/models/factories/AbstractFactory";
import BankLoanConfigStatusModel from "@/models/BankLoanConfigStatusModel";
import StatusFactory from "@/models/factories/StatusFactory";
import StatusGroupFactory from "@/models/factories/StatusGroupFactory";
import CheckpointFactory from "@/models/factories/CheckpointFactory";

class BankLoanConfigStatusFactory extends AbstractFactory {
  /**
   *
   * @param {object} object
   * @param {object} options
   * @return {BankLoanConfigStatusModel}
   */
  create({ object = {}, options = {} } = {}) {
    const {
      status = StatusFactory.create(),
      status_group = StatusGroupFactory.create(),
      checkpoint = CheckpointFactory.create(),
    } = object;

    return new BankLoanConfigStatusModel({
      order: null,
      lender_description: null,
      borrower_description: null,
      backoffice_description: null,
      status_id: null,
      ...object,
      status: StatusFactory.create({ object: status }),
      checkpoint: CheckpointFactory.create({ object: checkpoint }),
      status_group: StatusGroupFactory.create({ object: status_group }),
    });
  }
}

export default new BankLoanConfigStatusFactory();
