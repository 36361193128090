import { GenericJSONBData } from "@/types/models/utils/typedefs";
import { IOccupationFinancialInformation } from "@/types/proposal/simulation/occupationFinancialInformation";

export default class OccupationFinancialInformationModel {
  public id: number;
  public occupation_id: number;
  public gross_salary: GenericJSONBData<number>;
  public net_salary: GenericJSONBData<number>;
  public system_margin: GenericJSONBData<number>;
  public payslip_margin: GenericJSONBData<number>;
  public benefit_history_margin: GenericJSONBData<number>;
  constructor({
    id,
    occupation_id,
    gross_salary,
    net_salary,
    system_margin,
    payslip_margin,
    benefit_history_margin,
  }: IOccupationFinancialInformation) {
    this.id = id;
    this.occupation_id = occupation_id;
    this.gross_salary = gross_salary;
    this.net_salary = net_salary;
    this.system_margin = system_margin;
    this.payslip_margin = payslip_margin;
    this.benefit_history_margin = benefit_history_margin;
  }
}
