import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { inject, injectable, TYPES } from "@/arch/@core/main";
import { LoanInstallmentDTO } from "@/arch/@core/application/DTOs/LoanInstallmentDataGatewayDTO";
import { LoanInstallmentDataGateway } from "@/arch/@core/application/protocols/LoanInstallmentDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class LoanInstallmentGateway implements LoanInstallmentDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  // Todo Olha o LoanInstallmentResource e ver um lugar melhor para colocar o if com WhitelabelHandler
  getLoanInstallments(paramsDto: LoanInstallmentDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/public/installments",
      params: paramsDto.toRequest(),
    });
  }
}
