import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class LoanInstallmentResource extends AbstractOnboardResource {
  /**
   * @typedef {Object} LoanInstallmentsResult
   * @property {boolean} error
   * @property {Object} data
   * @property {number[]} data.default
   * @property {Object} response
   */

  /**
   * @param {Object} params
   * @param {number} [params.covenantId]
   * @param {number} [params.loanTypeId]
   * @param {number} [params.roleId]
   * @param {number} [params.loanCategoryId]
   * @param {number | string} [params.bank_id]
   * @param {string} [params.proposalId]
   * @param {number} [params.modality_id]
   * @returns {Promise<LoanInstallmentsResult>}
   */
  async getLoanInstallments({
    covenantId,
    loanTypeId,
    roleId,
    loanCategoryId,
    bank_id,
    proposalId,
    modality_id,
  }) {
    try {
      const response = await this.api.get({
        route: "/public/installments",
        params: {
          modality_id,
          loan_type_id: loanTypeId,
          role_id: roleId,
          covenant_id: covenantId,
          loan_category_id: loanCategoryId,
          bank_id,
          proposal_id: proposalId,
        },
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response || {},
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: {},
          responseObject: e,
        },
      });
    }
  }
}
