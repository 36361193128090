import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { TemplateDataGateway } from "@/arch/@core/application/protocols/TemplateDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class TemplateGateway implements TemplateDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getTemplate(
    proposalId: string | number,
    status_code: string | null = null
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/template`,
      params: {
        status_code,
      },
    });
  }
}
