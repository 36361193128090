export default class CheckpointModel {
  /**
   *
   * @param {string | null} code
   * @param {string | null} description
   * @param {number | null} id
   * @param {number | null} order
   * @param {boolean} visible
   * @param {string} name
   */
  constructor({ code, description, id, order, visible, name } = {}) {
    this.code = code;
    this.description = description;
    this.id = id;
    this.order = order;
    this.visible = visible;
    this.name = name;
  }
}
