import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { QuizzDataGateway } from "@/arch/@core/application/protocols/QuizzDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class QuizzGateway implements QuizzDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  generateQuiz(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `proposals/${proposalId}/quiz/new`,
    });
  }

  getProposalQuiz(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `proposals/${proposalId}/quiz`,
    });
  }

  postAnswersQuiz(proposalId: string | number, answers: number[]): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/quiz/answers`,
      body: { answers },
    });
  }
}
