import { IProposalModel } from "@/types/models/Proposal";

const state = {
  borrower: {
    activeTab: "active",
    length: 3,
    proposals: {
      active: {
        proposals: [] as IProposalModel[],
        pages: 0,
        page: 1,
      },
      aberto: {
        proposals: [] as IProposalModel[],
        pages: 0,
        page: 1,
      },
      vigente: {
        proposals: [] as IProposalModel[],
        pages: 0,
        page: 1,
      },
      cancelado: {
        proposals: [] as IProposalModel[],
        pages: 0,
        page: 1,
      },
      concluido: {
        proposals: [] as IProposalModel[],
        pages: 0,
        page: 1,
      },
      pendenciado: {
        proposals: [] as IProposalModel[],
        pages: 0,
        page: 1,
      },
    },
  },
};

export default state;
