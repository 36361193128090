import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { AddressDataGateway } from "@/arch/@core/application/protocols/AddressDataGateway";
import { UserAddressDTO } from "@/arch/@core/application/DTOs/UserAddressDataGatewayDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class AddressGateway implements AddressDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getUserAddress(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/addresses",
    });
  }

  getValidatedUserAddress(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/validated-address`,
    });
  }

  adminFind(userId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/users/${userId}/addresses`,
    });
  }

  getAddressProposal(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/addresses`,
    });
  }

  adminUpdateUserAddress(
    userId: string | number,
    paramsDto: UserAddressDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.put({
      url: `/users/${userId}/addresses`,
      body: paramsDto,
    });
  }

  updateAddressesProposalUser(
    proposalId: string | number,
    paramsDto: UserAddressDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.put({
      url: `/proposals/${proposalId}/addresses`,
      body: paramsDto,
    });
  }

  createAddressesProposalUser(
    proposalId: string | number,
    paramsDto: UserAddressDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/addresses`,
      body: paramsDto,
    });
  }

  adminCreateUserAddress(
    userId: string | number,
    paramsDto: UserAddressDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/users/${userId}/addresses`,
      body: paramsDto,
    });
  }

  createUserAddress(paramsDto: UserAddressDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: "/addresses",
      body: paramsDto,
    });
  }

  updateUserAddress(paramsDto: UserAddressDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.put({
      url: "/addresses",
      body: paramsDto,
    });
  }
}
