import { ToDomainMapper } from "@/arch/@core/application/mapper";
import { BlufinSimulatedLoanValues } from "@/arch/new_proposal/blufin/adapter/dtos/BlufinSimulatedLoanValuesDTO";
import { BlufinLoanSimulationInstallments } from "@/arch/new_proposal/blufin/domain/entities/BlufinLoanSimulationInstallments";
import { BlufinLoanSimulation } from "@/arch/new_proposal/blufin/domain/entities/BlufinLoanSimulation";
import { injectable } from "@/arch/@core/main";
@injectable()
export class BlufinLoanSimulationMapper
  implements ToDomainMapper<BlufinSimulatedLoanValues[], BlufinLoanSimulation>
{
  toDomain(data: BlufinSimulatedLoanValues[]): BlufinLoanSimulation {
    if (!data.length) {
      throw new Error();
    }

    const fistInstallment = data[0];

    const installments = data.map<BlufinLoanSimulationInstallments>((item) => ({
      availableValue: item.availableValue,
      installmentValue: item.installmentValue,
      installmentsNumber: item.installmentsNumber,
      monthCet: item.monthCet,
      monthTax: item.monthTax,
    }));

    return {
      cnpjAssignee: fistInstallment.cnpjAssignee,
      disbursementDate: fistInstallment.disbursementDate,
      firstPaymentDate: fistInstallment.firstPaymentDate,
      installments,
    };
  }
}
