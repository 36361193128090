import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { BankLoanDataGateway } from "@/arch/@core/application/protocols/BankLoanRepository";

@injectable()
export class BankLoanGateway implements BankLoanDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  find(proposalId: string | number, paramsDto: string): Promise<HttpClient.Response> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/bank-loan-configuration`,
      params: {
        includes: paramsDto,
      },
    });
  }
}
