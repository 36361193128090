import { inject, injectable, TYPES } from "@/arch/@core/main";
import { BlufinDataGateway } from "@/arch/new_proposal/blufin/application/protocols/BlufinDataGateway";
import { Usecase, UsecaseResponse } from "@/arch/@core/application/protocols/Usecase";
import { CreditLimitDTO } from "@/arch/new_proposal/blufin/adapter/dtos/CreditLimitDTO";
import { HttpStatusCode } from "@/arch/@core/constants/HttpStatusCode";

type FindCreditLimitParams = {
  cpf: string;
};

@injectable()
export class FindCreditLimit
  implements Usecase<FindCreditLimitParams, Promise<UsecaseResponse<CreditLimitDTO>>>
{
  constructor(@inject(TYPES.BlufinDataGateway) private gateway: BlufinDataGateway) {}
  async execute(params: FindCreditLimitParams): Promise<UsecaseResponse<CreditLimitDTO>> {
    const { data, status } = await this.gateway.getCreditLimit(params.cpf);

    switch (status) {
      case HttpStatusCode.conflict: {
        return {
          error: {
            type: "info",
            message: data.message,
            hideHelpIcon: true,
          },
        };
      }
    }

    return {
      data: data.response,
    };
  }
}
