import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { OccupationDTO } from "@/arch/@core/application/DTOs/OccupationDTO";
import { OccupationsUserDataGateway } from "@/arch/@core/application/protocols/OccupationsUserDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class OccupationsUserGateway implements OccupationsUserDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  adminCreate(userId: string | number, paramsDto: OccupationDTO): Promise<GatewayResponsee<any>> {
    return this.httpClient.post({
      url: `/users/${userId}/occupations`,
      body: paramsDto.toRequest(),
    });
  }

  adminFind(userId: string | number, paramsDto: OccupationDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/users/${userId}/occupations`,
      body: paramsDto.toRequest(),
    });
  }

  create(paramsDto: OccupationDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: "/occupations",
      body: paramsDto.toRequest(),
    });
  }

  find(paramsDto: OccupationDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/occupations",
      body: paramsDto.toRequest(),
    });
  }

  update(paramsDto: OccupationDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.put({
      url: "/occupations",
      body: paramsDto.toRequest(),
    });
  }
}
