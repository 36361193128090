import AbstractFactory from "@/models/factories/AbstractFactory";
import OccupationFinancialInformationModel from "@/models/OccupationFinancialInformationModel";

class OccupationFinancialInformationFactory extends AbstractFactory {
  /**
   * @param {object} object
   * @param {object} options
   * @returns {OccupationFinancialInformationModel}
   */
  create({ object = {}, options = {} } = {}) {
    const {
      id,
      occupation_id,
      gross_salary,
      net_salary,
      system_margin,
      payslip_margin,
      benefit_history_margin,
    } = object;
    return new OccupationFinancialInformationModel({
      id,
      occupation_id,
      gross_salary: gross_salary,
      net_salary: net_salary,
      system_margin: system_margin,
      payslip_margin,
      benefit_history_margin,
    });
  }
}

export default new OccupationFinancialInformationFactory();
