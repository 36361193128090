import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { StateDataGateway } from "@/arch/@core/application/protocols/StateDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class StateGateway implements StateDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getStates(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/public/states",
    });
  }
}
