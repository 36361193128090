import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

interface CreateSpouseParams {
  name: string;
  cpf: string;
  user_id: number;
  occupation?: string;
}

interface ISpouse {
  id: number;
  name: string;
  cpf: string;
  occupation: string;
  user_id: number;
}

export default class SpouseResource extends AbstractOnboardResource {
  async getSpouseByUser(userId: number): Promise<ISpouse | null> {
    const response = await this.api.get({
      route: "/spouses",
      params: {
        user_id: userId,
      },
    });

    return response.data?.response || null;
  }

  async getSpouseById(spouseId: number): Promise<ISpouse | null> {
    const response = await this.api.get({
      route: "/spouses",
      params: {
        id: spouseId,
      },
    });

    return response.data?.response || null;
  }

  async createSpouse(payload: CreateSpouseParams) {
    const response = await this.api.post({
      route: "/spouses",
      payload,
    });

    return response.data.response || null;
  }

  async editSpouse(spouseId: number, payload: Omit<CreateSpouseParams, "cpf" | "user_id">) {
    const response = await this.api.put({
      route: `/spouses/${spouseId}`,
      payload,
    });

    return response.data.response || null;
  }
}
