import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

import pt from "vuetify/src/locale/pt";
import { IColors } from "@/types/whitelabel";

export const initVuetify = (colors: IColors) =>
  new Vuetify({
    lang: {
      locales: { pt },
      current: "pt",
    },
    theme: {
      options: {
        customProperties: true,
      },
      dark: false,
      themes: {
        light: { ...colors },
      },
    },
    breakpoint: {
      thresholds: {
        xs: 500,
        sm: 800,
        md: 1270,
        lg: 1400,
      },
    },
  });
