import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class ModalityResource extends AbstractOnboardResource {
  async getModalities(modalityCodes) {
    try {
      const response = await this.api.get({
        route: "/public/modalities",
      });

      return this.resourceReturnFactory.create({
        object: {
          data: response?.data?.response.filter((modality) =>
            modalityCodes.includes(modality.code)
          ),
          error: false,
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: {},
          responseObject: e,
        },
      });
    }
  }

  async getModalitiesWithoutFilter() {
    const { data } = await this.api.get({
      route: "/public/modalities",
    });

    return data?.response;
  }

  async getModalitiesByCovenant({ covenantId }) {
    try {
      const response = await this.api.get({ route: `/covenants/${covenantId}/modalities` });
      return response?.data?.response;
    } catch (e) {
      return e?.response?.data;
    }
  }
}
