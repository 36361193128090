import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import OccupationFactory from "@/models/factories/OccupationFactory";

export default class OccupationsProposalResource extends AbstractOnboardResource {
  /**
   * @param {{}} payload
   * @param payload.proposalId
   * @param {any=} payload.includes
   * @return {Promise<ResourceReturnModel>}
   */
  async find({ proposalId, includes }) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/occupations`,
        params: { includes },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: OccupationFactory.create({ object: response.data.response }),
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: OccupationFactory.create(),
        },
      });
    }
  }

  /**
   *
   * @param {*} covenantId
   * @param {*} roleId?
   * @param {string | number } proposalId
   * @param {*} params
   * @param {number} external_id
   * @return {Promise<ResourceReturnModel>}
   */
  async update({ covenantId, roleId, proposalId, external_id, ...params }) {
    try {
      const response = await this.api.put({
        route: `/proposals/${proposalId}/occupations`,
        payload: { covenant_id: covenantId, role_id: roleId, external_id, ...params },
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: OccupationFactory.create({ object: response.data.response }),
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: OccupationFactory.create(),
        },
      });
    }
  }
}
