import { ICovenantModel } from "@/types/models/Covenant";

export default class CovenantModel {
  public id: number;
  public name: string;
  public code: string;
  public attended: boolean;
  public group: [];
  public description: string;
  public help_text: string;

  constructor({ id, name, code, attended, group, description, help_text }: ICovenantModel) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.attended = attended;
    this.group = group;
    this.description = description;
    this.help_text = help_text;
  }
}
