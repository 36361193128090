import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class PluginsResource extends AbstractOnboardResource {
  async sendPluginData(code: string, payload: any) {
    try {
      const response = await this.api.post({ route: `plugins/${code}`, payload });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }
}
