// @ts-ignore
import { BankLoanConfiguration } from "@/resources/onboard/BankLoanConfigurationResource";

export type PossibleScreens = { name: string; code: string; selectedCard?: string | number };

export type BankLoanConfigState = {
  bankLoanConfigs: BankLoanConfiguration[];
  hasBankLoanConfig?: boolean;
  storageCards: { code: string; name: string };
  possibleScreens: PossibleScreens[];
  hasAutomaticBankingConfig: boolean;
};

const state = {
  bankLoanConfig: [],
  hasBankLoanConfig: undefined,
  storageCards: {},
  possibleScreens: [
    {
      name: "Perfil",
      code: "modality",
    },
    {
      name: "Cargo",
      code: "role",
    },
    {
      name: "Tipo",
      code: "loan_type",
    },
  ],
  hasAutomaticBankingConfig: false,
};

export default state;
