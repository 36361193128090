import Vue from "vue";
import * as Sentry from "@sentry/vue";
import VueRouter from "vue-router";

export function initSentry(router: VueRouter) {
  const integrations: any = [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ];

  // configura sentry replay apenas para produção
  if (import.meta.env.VITE_ENVIRONMENT === "production") {
    integrations.push(
      new Sentry.Replay({
        networkDetailAllowUrls: [window.location.origin, "https://api.dinheirow.com.br"],
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      })
    );
  }

  Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_VUE_APP_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: integrations,
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_ENVIRONMENT,
    tracingOptions: {
      trackComponents: true,
    },
    attachProps: true,
  });
}
