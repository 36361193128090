import AbstractFactory from "@/models/factories/AbstractFactory";
import StatusModel from "@/models/StatusModel";

class StatusFactory extends AbstractFactory {
  /**
   *
   * @param {object} object
   * @param {object} options
   * @return {StatusModel}
   */
  create({ object = {}, options = {} } = {}) {
    return new StatusModel({
      id: null,
      code: null,
      is_blocking: null,
      is_alternative: null,
      status_group_id: null,
      is_checkpoint: null,
      ...object,
    });
  }
}

export default new StatusFactory();
