import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";
import { SMSDataGateway } from "@/arch/@core/application/protocols/SMSDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class SMSGateway implements SMSDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  postSMSValidation(proposalId: string | number, code: string): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/messages/confirmation`,
      body: { code },
    });
  }

  sendSMS(
    proposalId: string | number,
    message_sending_type?: EMessageSendingType
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/messages/send-phone-validation-token`,
      body: {
        message_sending_type,
      },
    });
  }
}
