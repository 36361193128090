import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { ComplianceDataGateway } from "@/arch/@core/application/protocols/ComplianceDataGateway";
import { GetProposalComplianceParamsDTO } from "@/arch/@core/application/DTOs/ComplianceDataGatewayDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class ComplianceGateway implements ComplianceDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  getProposalCompliance(
    proposalId: string | number,
    paramsDto: GetProposalComplianceParamsDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/compliance-logs`,
      params: paramsDto,
    });
  }
}
