import AbstractFactory from "@/models/factories/AbstractFactory";
import RoleModel from "@/models/RoleModel";

class RoleFactory extends AbstractFactory {
  /**
   * @param {object} object
   * @param {object} options
   * @returns {RoleModel}
   */
  create({ object = {}, options = {} } = {}) {
    return new RoleModel(object);
  }
}

export default new RoleFactory();
