import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { PoliticallyExposedDataGateway } from "@/arch/@core/application/protocols/PoliticallyExposedDataGateway";
import { PoliticallyExposedDTO } from "@/arch/@core/application/DTOs/PoliticallyExposedDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class PoliticallyExposedGateway implements PoliticallyExposedDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  createPPE(
    proposalId: string | number,
    paramsDto: PoliticallyExposedDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/pep`,
      body: paramsDto.toRequest(),
    });
  }

  getPPE(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/pep`,
    });
  }

  getPPETypes(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/pep-types`,
    });
  }
}
