import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { RoleDataGateway } from "@/arch/@core/application/protocols/RoleDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class RoleGateway implements RoleDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getRoles(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/public/roles",
    });
  }
}
