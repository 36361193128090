import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class LoanCategoryResource extends AbstractOnboardResource {
  /**
   *
   * @return {Promise<ResourceReturnModel>}
   */
  async index({ shouldQueue } = {}) {
    try {
      const response = await this.api.get({ route: "/public/loan-categories", shouldQueue });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response?.rows || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }
}
