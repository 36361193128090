import AbstractFactory from "@/models/factories/AbstractFactory";
import CheckpointModel from "@/models/CheckpointModel";

class CheckpointFactory extends AbstractFactory {
  /**
   *
   * @param {object} object
   * @param {object} options
   * @return {CheckpointModel}
   */
  create({ object = {}, options = {} } = {}) {
    return new CheckpointModel({
      id: null,
      description: null,
      code: null,
      order: null,
      visible: false,
      name: "",
      ...object,
    });
  }
}

export default new CheckpointFactory();
