import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class StateResource extends AbstractOnboardResource {
  /**
   *
   * @return {Promise<ResourceReturnModel>}
   */
  async getStates() {
    try {
      const response = await this.api.get({ route: "/public/states" });
      return this.resourceReturnFactory.create({
        object: { data: response?.data?.response || [], responseObject: response, error: false },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: { data: [], responseObject: e, error: true },
      });
    }
  }
}
