import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import ResourceReturnModel from "@/models/ResourceReturnModel";
import ResourceReturnFactory from "@/models/factories/ResourceReturnFactory";

export default class AddressResource extends AbstractOnboardResource {
  /**
   *
   * @return {Promise<ResourceReturnModel>}
   */
  async getUserAddress() {
    try {
      const response = await this.api.get({ route: "/addresses" });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async getValidatedUserAddress(proposalId: number) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/validated-address` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param userId
   * @return {Promise<ResourceReturnModel>}
   */
  async adminFind(userId: number) {
    try {
      const response = await this.api.get({ route: `/users/${userId}/addresses` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async getAddressProposal(proposalId: number) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/addresses` });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async adminUpdateUserAddress({
    city,
    complement,
    neighborhood,
    number,
    state,
    state_id,
    street,
    zip_code,
    userId,
  }: any) {
    try {
      const response = await this.api.put({
        route: `/users/${userId}/addresses`,
        payload: { city, complement, neighborhood, number, state, state_id, street, zip_code },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param city
   * @param complement
   * @param id
   * @param neighborhood
   * @param number
   * @param state
   * @param state_id
   * @param street
   * @param zip_code
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async updateAddressesProposalUser({
    city,
    complement,
    id,
    neighborhood,
    number,
    state,
    state_id,
    street,
    zip_code,
    proposalId,
  }: any) {
    try {
      const response = await this.api.put({
        route: `/proposals/${proposalId}/addresses`,
        payload: {
          city,
          complement,
          id,
          neighborhood,
          number,
          state,
          state_id,
          street,
          zip_code,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param payload
   * @param proposalId
   * @param update
   * @return {Promise<ResourceReturnModel>}
   */
  async createOrUpdateAddressesProposalUser({ payload, proposalId, update }: any) {
    if (update) {
      return await this.updateAddressesProposalUser({ ...payload, proposalId });
    } else {
      return await this.createAddressesProposalUser({ ...payload, proposalId });
    }
  }

  async createAddressesProposalUser({
    city,
    complement,
    id,
    neighborhood,
    number,
    state,
    state_id,
    street,
    zip_code,
    proposalId,
  }: any) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/addresses`,
        payload: { city, complement, id, neighborhood, number, state, state_id, street, zip_code },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async adminCreateUserAddress({
    city,
    complement,
    neighborhood,
    number,
    state_id,
    street,
    zip_code,
    userId,
  }: any) {
    try {
      const response = await this.api.post({
        route: `/users/${userId}/addresses`,
        payload: {
          city,
          complement,
          neighborhood,
          number,
          state_id,
          street,
          zip_code,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async createUserAddress({
    city,
    complement,
    neighborhood,
    number,
    state,
    state_id,
    street,
    zip_code,
  }: any) {
    try {
      const response = await this.api.post({
        route: "/addresses",
        payload: {
          city,
          complement,
          neighborhood,
          number,
          state,
          state_id,
          street,
          zip_code,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async updateUserAddress({
    city,
    complement,
    neighborhood,
    number,
    state,
    state_id,
    street,
    zip_code,
  }: any) {
    try {
      const response = await this.api.put({
        route: "/addresses",
        payload: { city, complement, neighborhood, number, state, state_id, street, zip_code },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async createOrUpdateUserAddress({ payload, userId, update }: any) {
    if (update) {
      return await this.updateUserAddress({ ...payload, userId });
    } else {
      return await this.createUserAddress({ ...payload });
    }
  }

  async adminCreateOrUpdateUserAddress({ payload, userId, update }: any) {
    if (update) {
      return await this.adminUpdateUserAddress({ ...payload, userId });
    } else {
      return await this.adminCreateUserAddress({ ...payload, userId });
    }
  }

  async searchAddress(cep: {} | string): Promise<ResourceReturnModel> {
    try {
      const response = await this.api.get({ route: "/public/ceps/" + cep });
      return ResourceReturnFactory.create({
        object: { data: response?.data?.response || {}, responseObject: response, error: false },
      });
    } catch (e) {
      return ResourceReturnFactory.create({
        object: { data: {}, responseObject: e, error: true },
      });
    }
  }
}
