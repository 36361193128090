import AbstractUrlHandler from "./AbstractUrlHandler";

class PageNotFoundHandler extends AbstractUrlHandler {
  constructor() {
    super();
    this.url = "/page-not-found";
  }

  accepts({ to }) {
    return !to.name;
  }

  process({ next }) {
    next(this.url);
  }
}

export default new PageNotFoundHandler();
