import VueRouter, { RouteConfig } from "vue-router";

import UrlProcessor from "@/middlewares/router_guardian/chain_before/UrlProcessor";
import { Store } from "vuex";
import { IBrand } from "@/types/whitelabel";

interface Props {
  baseUrl?: string;
  brandRoutes?: RouteConfig[];
}

const isProduction = import.meta.env.MODE === "production";

export const initRouter = ({ baseUrl, brandRoutes = [] }: Props, store: Store<unknown>) => {
  const newRouter = new VueRouter({
    mode: isProduction ? "history" : "hash",
    base: isProduction ? baseUrl : baseUrl + "#",
    routes: [
      {
        path: "/workspace",
        name: "Workspace",
        component: () => import("@/pages/workspace/Workspace.vue"),
      },
      {
        path: "/proposals/:proposalId/auth",
        name: "ProposalAuth",
        meta: { requiresAuth: false },
        component: () => import("@/pages/auth/proposal/ProposalAuth.vue"),
      },
      {
        path: "/login",
        name: "Login",
        meta: { requiresAuth: false },
        component: () => import("@/pages/login/LoginPage.vue"),
      },
      {
        path: "/documents/contracts",
        name: "ContractViewer",
        meta: { requiresAuth: false },
        component: () => import("@/pages/contract_viewer/ContractViewer.vue"),
      },
      {
        path: "/confirmation-code",
        name: "ConfirmationCode",
        component: () => import("@/pages/confirmations/ConfirmationCode.vue"),
        children: [
          {
            path: "password-recovery/:token",
            name: "RecoveryPassword",
            meta: { requiresAuth: false },
            component: () => import("@/pages/confirmations/recovery_password/ResetPassword.vue"),
          },
          {
            path: "new-device/:token",
            name: "NewDevice",
            meta: { requiresAuth: false },
            component: () => import("@/pages/confirmations/new_device/NewDevice.vue"),
          },
          {
            path: "document-mobile/proposals/:proposalId",
            name: "DocumentMobile",
            meta: { requiresAuth: false },
            component: () => import("@/pages/confirmations/document_mobile/DocumentMobile.vue"),
          },
          {
            path: "confirm-email/:token",
            name: "ConfirmEmail",
            meta: { requiresAuth: false },
            component: () => import("@/pages/confirmations/confirm_email/ConfirmEmail.vue"),
          },
        ],
      },
      {
        path: "/dashboard",
        component: () => import("@/layout/dashboard/Dashboard.vue"),
        children: [
          {
            path: "/",
            name: "DashboardHome",
            meta: { requiresAuth: true },
            component: () => import("@/pages/dashboard/home/DashboardHome.vue"),
          },
          {
            path: "user/:userId",
            name: "User",
            meta: { requiresAuth: true, isAdminOnly: true },
            component: () => import("@/pages/dashboard/user/User.vue"),
          },
          {
            path: "proposals/:proposalId/:userId?/pending",
            name: "ProposalPending",
            meta: { requiresAuth: true },
            component: () => import("@/pages/dashboard/proposal/pending/ProposalPending.vue"),
          },
          {
            path: "proposals/:proposalId?/:userId?",
            name: "DashboardSimulation",
            meta: { requiresAuth: true },
            component: () =>
              import("@/pages/dashboard/proposal/simulation/DashboardSimulation.vue"),
          },
          {
            path: "proposals/:proposalId/:userId/status",
            name: "DashboardProposalStatus",
            meta: { requiresAuth: true, isAdminOnly: true },
            component: () => import("@/pages/dashboard/proposal/status/ProposalStatus.vue"),
          },
          {
            path: "profile",
            name: "DashboardProfile",
            meta: { requiresAuth: true },
            component: () => import("@/pages/dashboard/profile/DashboardProfile.vue"),
          },
        ],
      },
      {
        path: "/faq",
        name: "Faq",
        component: () => import("@/pages/faq/Faq.vue"),
      },
      {
        path: "/termos-de-uso",
        name: "TermsOfUse",
        component: () => import("@/pages/terms_and_privacy/TermsAndPrivacy.vue"),
      },
      {
        path: "/politicas-de-privacidade",
        name: "Privacy",
        component: () => import("@/pages/terms_and_privacy/TermsAndPrivacy.vue"),
      },
      {
        path: "/redirect",
        name: "Redirect",
        component: () => import("@/pages/page_redirect/PageRedirect.vue"),
      },
      {
        path: "/page-not-found",
        name: "PageNotFound",
        component: () => import("@/pages/not_found/NotFound.vue"),
      },
      {
        path: "/auth-redirect",
        name: "AuthRedirect",
        meta: { requiresAuth: false },
        component: () => import("@/pages/auth/auth_redirect/AuthRedirect.vue"),
      },
      {
        path: "/external-auth",
        name: "ExternalAuth",
        meta: { requiresAuth: false },
        component: () => import("@/pages/external-auth/ExternalAuth.vue"),
      },
      ...brandRoutes,
    ],
  });

  newRouter.beforeEach((to, from, next) => {
    let { showUserData }: IBrand = store.getters.whiteLabel;
    showUserData = showUserData ?? true;

    UrlProcessor.run({ to, from, next });

    if (to.name === "DashboardProfile" && !showUserData) {
      newRouter.push("/dashboard");
      return "/dashboard";
    }
  });

  return newRouter;
};
