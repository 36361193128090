import Vue from "vue";
import { extend, localize, ValidationObserver, ValidationProvider } from "vee-validate";
import * as Rules from "vee-validate/dist/rules";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import {
  dateIntervalValidate,
  hasEmptySpaces,
  loanValueMax,
  loanValueMin,
  maximumValue,
  minimumCharacters,
  minimumValue,
  validateDate,
  validateUniqueValue,
  validateUniqueValues,
  validationCPF,
} from "@/utils/formRules";

const allRules = {
  validationCPF,
  dateValidation: validateDate,
  loanValueMax,
  hasEmptySpaces,
  ...Rules,
};

extend("minimumValue", {
  validate: minimumValue,
  params: ["minimumValue"],
});

extend("maximumValue", {
  validate: maximumValue,
  params: ["maximumValue", "allowZero"],
});

extend("validateUniqueValue", {
  validate: validateUniqueValue,
  params: ["uniqueValue"],
});

extend("loanValueMin", {
  validate: loanValueMin,
  params: ["minValue"],
});

extend("validateUniqueValues", {
  validate: validateUniqueValues,
  params: ["values"],
});

extend("minChars", {
  validate: minimumCharacters,
  params: ["min"],
});

extend("dateIntervalValidate", {
  validate: dateIntervalValidate,
  params: ["minDate", "maxDate"],
  computesRequired: true,
});

for (const rule in allRules) {
  extend(rule, allRules[rule]);
}

localize("pt_BR", pt_BR);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
