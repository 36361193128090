import AbstractFactory from "@/models/factories/AbstractFactory";
import { IProposalModel } from "@/types/models/Proposal";

class ProposalFactory extends AbstractFactory {
  create(object: Partial<IProposalModel> = {}) {
    const defaultValue = { required: 0, authorized: 0, adjusted: 0 };
    return {
      id: 0,
      gross_value: defaultValue,
      installment_value: defaultValue,
      loan_value: defaultValue,
      number_installments: defaultValue,
      main_value: defaultValue,
      nominal_rate_month: defaultValue,
      nominal_rate_year: defaultValue,
      nominal_cet_month: defaultValue,
      nominal_cet_year: defaultValue,
      total_iof: defaultValue,
      insurance_value: defaultValue,
      bank_loan_configuration_id: 0,
      proposal_id: 0,
      required_installments: 0,
      simulated_installment_value: 0,
      required_value: 0,
      created_at: "",
      updated_at: "",
      bank_proposal_id: 0,
      approved: false,
      approvation_date: "",
      approvation_limit_time: "",
      allow_general_contracts: false,
      allow_monthy_contracts: false,
      base_date: "",
      first_payment_date: "",
      last_payment_date: "",
      authorized_value: 0,
      cpmf_value: 0,
      annotation_date: "",
      deposit_date: "",
      document_flow_type: 0,
      loan_reason_id: 0,
      financial_rule_value: 0,
      price_table_path: "",
      simulated_installments: 0,
      base_installment_value: 0,
      ccb: 0,
      same_payslip_account: null,
      status_id: 0,
      sms_confirmed: false,
      iof_funded: false,
      authorized_installment_value: 0,
      authorized_installments: 0,
      lender: null,
      loan_type_id: 0,
      bank_id: 0,
      adjusted_value_by_rules: 0,
      adjusted_installments_by_rules: 0,
      adjusted_installments_value_by_rules: 0,
      required_installment_value: 0,
      is_processing: false,
      annual_effective_rate: 0,
      monthly_effective_rate: 0,
      actual_amount_borrowed: 0,
      active: false,
      consolidated_proposal_number: 0,
      peculio_value: 0,
      personal_accident_insurance_value: 0,
      outstanding_balance: 0,
      ref_id: 0,
      proposal_details: [],
      proposal_detail_simulateds: [],
      ...object,
    } as IProposalModel;
  }
}

export default new ProposalFactory();
