import dinheirow from "@/whitelabel/labels/dinheirow";
import arbi from "@/whitelabel/labels/arbi";
import corebanx from "@/whitelabel/labels/corebanx";
import bancoGenial from "@/whitelabel/labels/bancogenial";
import ciadocredito from "@/whitelabel/labels/ciadocredito";
import { bancoabc } from "@/whitelabel/labels/bancoabc";

const brands = [arbi, dinheirow, ciadocredito, bancoabc, bancoGenial, corebanx];

export default brands;
