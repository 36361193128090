import actions from "@/store/modules/statusGroup/actions";
import mutations from "@/store/modules/statusGroup/mutations";
import state from "@/store/modules/statusGroup/state";
// import getters from "@/store/modules/statusGroup/getters";

export const module = {
  namespaced: true,
  actions,
  mutations,
  state,
  // getters,
};
