// @ts-nocheck

import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { TemplateState } from "@/store/modules/template/state";
import { TemplateElement } from "@/types/models/TemplateElement";

const updateAdditionalData = (
  values: TemplateElement[]
): { additional_data?: any; hasAdditionalData: boolean } => {
  // eslint-disable-next-line no-prototype-builtins
  const hasAdditionalData = !!values.find((item) => item?.value.hasOwnProperty("additional_data"));

  if (!hasAdditionalData) return { hasAdditionalData };

  const additional_data = values
    .map((item) => {
      // seta todas as proprieadades de additional_data para o valor do checkbox
      if ("additional_data" in item.value) {
        Object.keys(item.value.additional_data as any).forEach((prop) => {
          item.value.additional_data[prop] = item.value.isChecked;
        });
      }

      return item.value.additional_data;
    })
    .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }));

  return { additional_data, hasAdditionalData: true };
};

const actions = {
  async updateProposalBasedOnCheckbox(
    { state, rootState, commit }: ActionContext<TemplateState, RootState>,
    payload: { proposalId: string | number }
  ) {
    if (state.checkbox.hasCheckBoxes) {
      const { additional_data, hasAdditionalData } = updateAdditionalData(state.checkbox.values);

      if (hasAdditionalData) {
        const { error } = await rootState.OnboardApi.onboardApi.resources.proposal.updateProposal({
          proposalId: payload.proposalId,
          additional_data,
        });

        if (!error) {
          commit("setCheckBox", []);
        }
      }
    }
  },
  async saveCheckBoxState(
    { commit }: ActionContext<TemplateState, RootState>,
    payload: TemplateElement[]
  ) {
    commit("setCheckBox", payload);
  },
};

export default actions;
