import { TemplateElement } from "@/types/models/TemplateElement";

const state = {
  checkbox: {
    hasCheckBoxes: false,
    values: [] as TemplateElement[],
  },
};

export type TemplateState = typeof state;

export default state;
