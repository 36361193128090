import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class AgreementResource extends AbstractOnboardResource {
  /**
   * @param {{}} formData
   * @param {string} formData.code
   * @param {string | number} formData.proposalId
   * @param {boolean=} formData.shouldQueue
   * @return {Promise<ResourceReturnModel>}
   */
  async create({ code, proposalId, shouldQueue }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/agreements/${code}`,
        shouldQueue,
      });
      return this.resourceReturnFactory.create({
        object: {
          data: response?.data?.response || {},
          error: false,
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          data: {},
          error: true,
          responseObject: e,
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async index({ proposalId }) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/required-agreements`,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response.data?.rule_definitions || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }
}
