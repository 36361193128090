import OnboardApiError from "@/errors/OnboardApiError";
import { Component, Inject, InjectReactive, Watch } from "vue-property-decorator";
import Geolocation from "@/services/Geolocation";
import AbstractFlowController from "@/controllers/abstracts/AbstractFlowController";
import FileConverters from "@/utils/FileConverters";
import { GeoLocalizationProps } from "@/arch/@core/application/DTOs/GeoLocalizationDTO";
import { IBankLoanConfigStatusModel } from "@/types/models/BankLoanConfigStatus";
import { State } from "vuex-class";
export interface IViewElementContract {
  code: string;
  element_type: string;
  id: number;
  value: string;
}

@Component({
  name: "Contract",
  components: {
    Refinancing: () => import("./arbi/refinancing/Refinancing.vue"),
    CCB: () => import("./arbi/ccb/CCB.vue"),
  },
})
export default class ContractController extends AbstractFlowController {
  @Inject("getCurrentComponent") getCurrentComponent!: () => { code: "" };
  @InjectReactive("isReadyToGetContract") isReadyToGetContract!: boolean;
  @State((state) => state.Proposal.bankLoanConfigStatus)
  bankLoanConfigStatus!: IBankLoanConfigStatusModel;

  contractUrlDownload = "";
  contract?: any;

  contractTemplate = {
    code: "",
    sub_title: "",
    title: "",
    view_elements: [] as IViewElementContract[],
  };

  helpMessage = this.showHelpButtons
    ? ' Clique no botão "pedir ajuda" para entrar em contato com o nosso suporte'
    : "";

  @Watch("contractTemplate")
  updateContract() {
    if (this.contract)
      this.contractTemplate.view_elements.forEach((item) => {
        if (item.element_type === "document") item.value = this.contract;
      });
  }

  get currentLoanType() {
    const filteredArray =
      this.contractTemplate.view_elements?.filter((item) => item?.element_type === "switch") || [];

    return {
      componentName: filteredArray.length > 1 ? "Refinancing" : "CCB",
    };
  }

  get shouldRenderContract() {
    return this.contractTemplate.view_elements?.length;
  }

  get contractURL() {
    return (
      this.contractTemplate?.view_elements?.find((item) => item.element_type === "document")
        ?.value || ""
    );
  }

  async downloadContract() {
    await FileConverters.downloadPdfFromUrlUsingBlob(this.contract, "contrato");
  }

  @Watch("isReadyToGetContract")
  async onIsReadyToGetContractChange() {
    if (this.isReadyToGetContract) {
      await this.mountHandler();
    }
  }

  async mountHandler() {
    let contract = this.bankLoanConfigStatus.template?.find(
      (element) => element.element_type === "document"
    )?.value;

    if (!contract) contract = await this.getContract();

    if (contract) {
      this.contract = contract;
      await Promise.allSettled([
        !this.bankLoanConfigStatus.template ? this.templateHandler() : null,
        this.getDownloadURL(contract),
      ]);
    }
  }

  private async templateHandler() {
    this.contractTemplate = await this.getTemplate();
  }

  private async getTemplate() {
    const { code } = this.getCurrentComponent();
    const { proposalId = 0 } = this.$route.params;
    const { data, error, responseObject } = await this.OnboardAPI.resources.template.getTemplate({
      proposalId,
      code,
    });
    if (error) throw new OnboardApiError(responseObject);
    return data;
  }

  private base64toBlobURL(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([int8Array], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  }

  private async getContract() {
    try {
      const { proposalId = 0 } = this.$route.params;
      const { error, data } = await this.OnboardAPI.resources.contract.getContract({
        proposalId,
      });

      if (!error) return this.base64toBlobURL(data);
      return "";
    } catch (e) {
      console.error(e);
      return "";
    }
  }

  private async getDownloadURL(contract: any) {
    const url = await FileConverters.getDownloadFileUrl(contract);
    this.contractUrlDownload = url || contract;
  }

  async executeTasks() {
    return await this.acceptContractHandler();
  }

  private async acceptContractHandler() {
    try {
      const { code, message, coords, error } = await Geolocation.getCurrentPosition();

      await this.acceptContract({
        latitude: error ? undefined : String(coords.latitude),
        long: error ? undefined : String(coords.longitude),
        code,
        message,
      });

      return true;
    } catch (e) {
      this.openInfoDialog({
        type: "error",
        title: "Assinatura de contrato",
        message: "Não conseguimos processar sua assinatura." + this.helpMessage,
      });
      return false;
    }
  }

  private async acceptContract({ code, message, long, latitude }: GeoLocalizationProps) {
    const { proposalId } = this.$route.params;

    const { error, responseObject } = await this.OnboardAPI.resources.contract.acceptContract({
      proposalId,
      authorize_consignment: true,
      code,
      message,
      long,
      lat: latitude,
    });

    if (error) throw new OnboardApiError(responseObject);

    return responseObject;
  }
}
