import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export interface TotvsUserData {
  bankId: number;
  hasError: boolean;
  age: string;
  cpf: string;
  spouseCpf: string;
  bankAccount: string;
  spouseName: string;
  maritalStatus: number;
  situation: string;
  admissionDate: string;
  salary: string;
  bankAgency: string;
  name: string;
  spouseRole: string;
  errorMessage: string;
  motherName: string;
  birthdate: string;
  permanenceTime: number;
  accountType: number;
  gender: number;
  phone: string;
  cep: string;
  city: string;
  complement: string;
  neighborhood: string;
  street: string;
  number: string;
  state_id: number;
  checkDigit: string;
  hasCalculatedTermination: boolean;
}

export default class TotvsResource extends AbstractOnboardResource {
  async getUserData(cpf: string): Promise<TotvsUserData> {
    const response = await this.api.get({
      route: "public/totvs/user-data",
      params: { cpf },
    });

    return response.data.response;
  }
}
