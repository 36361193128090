import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { StatusDataGateway } from "@/arch/@core/application/protocols/StatusDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class StatusGateway implements StatusDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  nextStatus(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/status/next`,
    });
  }

  getStatusProposal(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/status`,
    });
  }

  getStatusesProposal(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/statuses`,
    });
  }
}
