import ArbiLogo from "@/assets/SVG/logos/arbi-logo.svg";
import MinimalArbiLogo from "@/assets/SVG/minimal_logo/arbi.svg";
import { IBrand } from "@/types/whitelabel";
import defaultLoginImage from "@/assets/images/login-image.webp";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";

const arbi: IBrand = {
  brand: "arbi",
  hideWhatsappIcon: false,
  loginImages: [defaultLoginImage],
  borrowerCanCreateProposal: false,
  bankOrigin: "arbi",
  pageTitle: "Plataforma Arbi",
  partnerCode: "partner_arbi",
  favicon: "arbi.ico",
  logo: { src: ArbiLogo, width: 80, height: 80 },
  logoOnWhite: { src: ArbiLogo, width: 80, height: 80 },
  minimalLogo: { src: MinimalArbiLogo, width: 40, height: 40 },
  // TODO DEBITO TECNICO Rota para pegar os valores da proposta
  loanValues: {
    min: 300,
    max: 60000,
    cardValues: [5000, 10000, 20000, 30000, 50000],
  },
  contactEmail: "contato@arbi.com.br",
  contactWhatsappNumber: "+5511996636969",
  contactWhatsappMessage: "Olá, preciso de ajuda!",
  colors: {
    primary: "#18635e",
    secondary: "#239088",
    tertiary: "#fdfbeb",
    quaternary: "#1e7b75",
    quinary: "#7ad06d",
    grey: "#7a7a7a",
    "light-grey": "#fafafa",
    "primary-lighten": "#b7edf0",
    "tertiary-lighten": "#fdfbeb",
  },
  logoutUrl: "https://www.bancoarbi.com.br/",
  flowMessages: {
    maritalStatus: {
      title: "Informe o estado civil do cliente",
      description:
        "A informação correta do estado civil é muito importante para darmos mais agilidade ao pedido de empréstimo.",
    },
    naturalness: {
      title: "Informe o local de nascimento do cliente",
      description: "",
    },
    sameBankAccount: {
      title: "Deseja utilizar a mesma conta que está no contra-cheque/holerite do cliente?",
      description:
        "Você pode utilizar a mesma conta bancária que consta no Holerite (contra-cheque) do cliente ou cadastrar uma nova conta bancária para o financiamento.",
    },
    personalReferences: {
      title: "Informe uma referência pessoal do cliente",
      description: "",
    },
    person: {
      title: "O cliente é uma pessoa politicamente exposta?",
      description: "",
    },
    relationship: {
      title: "O cliente possui algum relacionamento próximo com uma pessoa politicamente exposta?",
      description: "",
    },
    addressConfirmation: {
      title: "Informações do endereço do cliente",
      description: "",
    },
    analysis: {
      title:
        "A proposta será analisada e quando aprovada, o cliente receberá um e-mail para dar continuidade à proposta.",
      description: "Para acompanhar o andamento você pode acessar o botão de status da proposta.",
    },
    endorsement: {
      title: "Estamos mais perto do que nunca!",
      description: `Parabéns! A proposta já foi encaminhada ao banco. O prazo para o recebimento é em média de 1 dia útil, podendo sofrer variações em função da disponibilidade do sistema do governo. Porém não se preocupe, estamos acompanhando todo o processo e entraremos em contato após a análise final pelo banco.`,
    },
    finalizedAnalysis: {
      title: "Parabéns!",
      description:
        "Sua análise foi aprovada!\n" +
        "Em caso de dúvida, não hesite em entrar em contato com um dos atendentes.",
    },
    awaitTransfer: {
      title: "Empréstimo aprovado!",
      description: `Seu dinheiro será transferido em breve. Em caso de dúvidas não hesite em entrar em contato pelo e-mail <span class="font-weight-bold">contato@dinheirow.com.br</span>`,
    },
  },
  specificRoutes: [
    {
      path: "/",
      name: "Landing",
      component: () => import("@/pages/login/LoginPage.vue"),
      meta: { requiresAuth: false },
    },
    {
      path: "/cadastro",
      name: "Register",
      meta: { requiresAuth: false },
      component: () => import("@/pages/register/RegisterPage.vue"),
      children: [
        {
          path: "arbi",
          name: "RegisterArbi",
          meta: { requiresAuth: false },
          props: { name: "Arbi" },
          component: () => import("@/components/register/Register.vue"),
        },
        {
          path: "contadigital",
          name: "DigitalAccount",
          meta: { requiresAuth: false },
          props: { name: "Conta Digital" },
          component: () => import("@/components/register/Register.vue"),
        },
      ],
    },
  ],
  modalityCodes: [
    "pensionista_inss",
    "militar",
    "servidor_municipal",
    "servidor_federal",
    "servidor_estadual",
    "cdc",
    "modality_fgts",
  ],
  partners: [
    "lwsduarte",
    "dinou",
    "fontes",
    "alphavox",
    "motorola",
    "johndeere",
    "cdc",
    "anticipay",
    "simonetti",
    "corebanx",
  ],
  messageSendingType: EMessageSendingType.WhatsApp,
};

export default arbi;
