import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { CreateAgreementParamsDTO } from "@/arch/@core/application/DTOs/AgreementProposalDataGatewayDTO";
import { AgreementProposalDataGateway } from "@/arch/@core/application/protocols/AgreementProposalDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class AgreementProposalGateway implements AgreementProposalDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  create({ proposalId, code }: CreateAgreementParamsDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/agreements/${code}`,
    });
  }

  getAgreements(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/required-agreements`,
    });
  }
}
