import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";

export default class RedirectResource extends AbstractOnboardResource {
  async continueOnMobile(proposalId: number, message_sending_type?: EMessageSendingType) {
    try {
      const response = await this.api.post({
        route: `proposals/${proposalId}/messages/mobile-document-upload`,
        payload: { message_sending_type },
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async getAuthRedirectUrl(proposalId: number, with_redirect_url = true) {
    const { data } = await this.api.post({
      route: `/proposals/${proposalId}/access-url`,
      payload: { with_redirect_url },
    });

    if (data && data.response) return data.response;

    return "";
  }
}
