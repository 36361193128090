import state from "@/store/modules/globalError/state";
import actions from "@/store/modules/globalError/actions";
import getters from "@/store/modules/globalError/getters";
import mutations from "@/store/modules/globalError/mutations";
export const GlobalError = {
  state,
  getters,
  mutations,
  actions,
};
