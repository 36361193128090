import { IRoleModel } from "@/types/models/Role";

export default class RoleModel {
  public id: number;
  public name: string;
  public code: string;
  public ordering: number;
  public description: string;
  public help_text: string;

  constructor({ id, name, code, ordering, description, help_text }: IRoleModel) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.ordering = ordering;
    this.description = description;
    this.help_text = help_text;
  }
}
