import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class StatusResource extends AbstractOnboardResource {
  async getStatus() {
    try {
      const response = await this.api.get({ route: "/public/status_groups?aggregate=1" });
      return this.resourceReturnFactory.create({
        object: { error: false, data: response?.data?.response || {}, responseObject: response },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: { error: true, data: {}, responseObject: e },
      });
    }
  }

  async nextStatus({ proposalId, shouldQueue = true }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/status/next`,
        shouldQueue,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response,
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: {},
          responseObject: e,
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async getStatusProposal({ proposalId }) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/status` });
      return this.resourceReturnFactory.create({
        object: {
          data: response?.data?.response || {},
          responseObject: response,
          error: false,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          data: {},
          responseObject: e,
          error: true,
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async getStatusesProposal({ proposalId }) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/statuses` });
      return this.resourceReturnFactory.create({
        object: {
          data: response?.data?.response || {},
          responseObject: response,
          error: false,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          data: {},
          responseObject: e,
          error: true,
        },
      });
    }
  }
}
