import { ISetProposal, Tabs } from "@components/dashboard/home/borrower/BorrowerHomeController";

const mutations = {
  setActiveTab(state: any, payload: Tabs) {
    state.borrower.activeTab = payload;
  },
  setProposals(state: any, payload: ISetProposal) {
    const { activeTab, length } = state.borrower;
    const totalPages = (totalProposals = 0) => {
      return Math.ceil(totalProposals / length);
    };

    state.borrower.proposals[activeTab].proposals = payload.proposals;
    state.borrower.proposals[activeTab].pages = totalPages(Number(payload.numberOfProposals));
  },
  setProposalPageToOne(state: any) {
    const { activeTab } = state.borrower;
    state.borrower.proposals[activeTab].page = 1;
  },
  setDefaultProposals(state: any, newDefaultProposals: any[]) {
    state.borrower.proposals = newDefaultProposals;
  },
};

export default mutations;
