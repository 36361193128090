import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { LoanReasonDataGateway } from "@/arch/@core/application/protocols/LoanReasonDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class LoanReasonGateway implements LoanReasonDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getLoanReasons(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/public/loan-reasons",
    });
  }
}
