import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { OccupationsProposalDataGateway } from "@/arch/@core/application/protocols/OccupationsProposalDataGateway";
import { OccupationDTO } from "@/arch/@core/application/DTOs/OccupationDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class OccupationsProposalGateway implements OccupationsProposalDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  find(proposalId: string | number, includes?: string): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/occupations`,
      params: {
        includes,
      },
    });
  }

  update(proposalId: string | number, paramsDto: OccupationDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.put({
      url: `/proposals/${proposalId}/occupations`,
      body: paramsDto.toRequest(),
    });
  }
}
