import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class TemplateResource extends AbstractOnboardResource {
  /**
   *
   * @param proposalId
   * @param code
   * @return {Promise<ResourceReturnModel>}
   */
  async getTemplate({ proposalId, code = null }) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/template`,
        params: {
          status_code: code,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response || {},
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: {},
          responseObject: e,
        },
      });
    }
  }
}
