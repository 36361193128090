import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import { IViewElementContract } from "@/components/dashboard/proposal/simulation/constructor_flow/contract/ContractController";

export type TermsOfUseCode = "policy_and_privacy" | "terms_of_use";

export default class TermsOfUseResource extends AbstractOnboardResource {
  async getTerms(code: TermsOfUseCode): Promise<IViewElementContract[]> {
    try {
      const response = await this.api.get({
        route: "/public/brands/pages",
        params: { code },
      });

      return response.data.response;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
}
