import { AxiosResponse } from "axios";

interface ResourceReturnFactoryParams {
  data: any;
  response: AxiosResponse;
}

class NewResourceReturnFactory {
  create<T>({ data, response }: ResourceReturnFactoryParams) {
    return {
      error: response.status >= 399,
      data: data as T,
      response,
    };
  }
}

export default new NewResourceReturnFactory();
