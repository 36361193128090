import UserFactory from "@/models/factories/UserFactory";

/**
 *
 * @type {{user: UserModel}}
 */
const state = {
  user: UserFactory.create({ object: {} }),
};

export default state;
