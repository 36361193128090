import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { ProposalDTO } from "@/arch/@core/application/DTOs/ProposalDTO";
import { SimulationDataGateway } from "@/arch/@core/application/protocols/SimulationDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class SimulationGateway implements SimulationDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  doPartnersSimulation(): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: "/partners/simulation",
    });
  }

  getPartnersSimulation(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/partners/simulation",
    });
  }

  postSimulationBasic(paramsDto: ProposalDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: "/public/simulation/basic",
      body: paramsDto.toRequest(),
    });
  }
}
