import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class LoanTypeResource extends AbstractOnboardResource {
  /**
   *
   * @return {Promise<ResourceReturnModel>}
   */
  async getLoanTypes() {
    try {
      const response = await this.api.get({ route: "/public/loan-types" });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response || {},
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: {},
          responseObject: e,
        },
      });
    }
  }
}
