import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class CompanyResource extends AbstractOnboardResource {
  /**
   *
   * @param companyCnpj
   * @return {Promise<ResourceReturnModel>}
   */
  async find({ companyCnpj }) {
    try {
      const response = await this.api.get({ route: `/companies/${companyCnpj}` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param companyId
   * @return {Promise<ResourceReturnModel>}
   */
  async findBankAccount({ companyId }) {
    try {
      const response = await this.api.get({
        route: `/companies/${companyId}/bank-account?includes=banks`,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }
}
