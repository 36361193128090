import AbstractRequestHandler from "./AbstractRequestHandler";

class AuthenticateDoneHandler extends AbstractRequestHandler {
  accepts(request) {
    return !!localStorage.getItem("Authorization");
  }

  process(request) {
    const isBackofficeRequest = request.baseURL.includes("backoffice");

    if (isBackofficeRequest) {
      request.headers.common["x-access-token"] = localStorage.getItem("Authorization");
    } else {
      request.headers.common.Authorization = localStorage.getItem("Authorization");
    }
  }
}

export default new AuthenticateDoneHandler();
