import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { inject, injectable, TYPES } from "@/arch/@core/main";
import { DocumentDataGateway } from "@/arch/@core/application/protocols/DocumentDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class DocumentGateway implements DocumentDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  // TODO rever implementação
  createDocument(proposalId: string | number, paramsDto: any): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/documents`,
      body: paramsDto,
    });
  }

  // TODO rever implementação
  find(proposalId: string | number, paramsDto: any): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/documents-configuration`,
      params: {},
    });
  }

  findAllRequiredAndNonRequiredDocuments(
    proposalId: string | number,
    ignore_upload_document: boolean
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/documents-configuration`,
      params: {
        ignore_upload_document,
      },
    });
  }

  getCurrentDocument(
    proposalId: string | number,
    documentCode: string | number
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/documents/${documentCode}?can_reuse=false`,
      params: { buffered: false },
    });
  }

  continueOnMobile(
    proposalId: string | number,
    message_sending_type: string
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `proposals/${proposalId}/messages/mobile-document-upload`,
      body: { message_sending_type },
    });
  }

  getAllDocumentsFromProposal(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/documents`,
    });
  }

  sendUploadDocumentLink(
    proposalId: string | number,
    message_sending_type: string
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/documents`,
      body: { message_sending_type },
    });
  }

  // TODO rever implementação
  getAllUploadedDocuments(proposalId: string | number): any {}
}
