import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { inject, injectable, TYPES } from "@/arch/@core/main";
import { CovenantDataGateway } from "@/arch/@core/application/protocols/CovenantDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class CovenantGateway implements CovenantDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getCovenants(modality_id?: number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/public/covenants",
      params: {
        modality_id,
      },
    });
  }

  find(covenantId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/public/covenants/${covenantId}`,
    });
  }

  getAssociatedRoles(covenant_id: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/public/covenant-roles",
      params: {
        covenant_id,
      },
    });
  }
}
