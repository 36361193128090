import "vue-class-component/hooks";
import "@/plugins/veeValidate";
import VueMeta from "vue-meta";
import Vue from "vue";
import { initVuetify } from "./plugins/vuetify";
import store from "./store";
import { initRouter } from "@/routes";
import VueMask from "v-mask";
import OnboardApi from "@/services/api/OnboardApi";
import WhiteLabelHandler from "@/whitelabel/WhiteLabelHandler";
import App from "./App.vue";
import VueRouter from "vue-router";
import Vuetify from "vuetify/lib";
import WhitelabelResource from "@/resources/onboard/WhitelabelResource";
import { IBrand } from "@/types/whitelabel";
import { DefaultHeaders } from "@/arch/@core/gateway/HttpClient";
import HttpClientAdapter from "@/arch/@core/adapter/HttpClientAdapter";
import { ContainerInstances } from "@/arch/@core/main/ioc/ContainerInstances";
import { initSentry } from "@/plugins/sentry";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueMask);
Vue.use(Vuetify);

Vue.prototype.$bus = new Vue();

export const whiteLabelHandler = new WhiteLabelHandler({
  whitelabelResource: new WhitelabelResource(),
  hostname: window.location.hostname,
  pathname: window.location.pathname,
  envBrand: import.meta.env.VITE_VUE_APP_WHITE_LABEL,
});

const initializeComponents = async (brand: IBrand) => {
  await store.dispatch("whiteLabel", brand);
  const router = initRouter({ baseUrl: brand.baseUrl, brandRoutes: brand.specificRoutes }, store);

  initSentry(router);

  const onboardApi = new OnboardApi({
    brand,
    baseURL: import.meta.env.VITE_VUE_APP_ONBOARD_URL!,
  });

  const dinheirowHeaders: DefaultHeaders = {
    "Bank-Origin": brand.bankOrigin,
    "Partner-Code": brand.partnerCode,
  };
  const partnersHeaders: DefaultHeaders = { ...dinheirowHeaders, "brand-id": brand.id };
  const defaultHeaders: DefaultHeaders = brand.id ? partnersHeaders : dinheirowHeaders;

  onboardApi.setInterceptors(router);
  onboardApi.setDefaultHeaders(defaultHeaders);
  HttpClientAdapter.setDefaultHeaders(defaultHeaders);

  await store.dispatch("onboardApi", onboardApi);

  new Vue({
    store,
    provide: { currentBrand: brand, ...ContainerInstances, onboardApi },
    vuetify: initVuetify(brand.colors),
    router,
    render: (h) => h(App),
  }).$mount("#app");
};

whiteLabelHandler.getBrand().then(async (brand) => {
  await initializeComponents(brand);
});
