import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import ContractController from "@/components/dashboard/proposal/simulation/constructor_flow/contract/ContractController";

export default class ContractResource extends AbstractOnboardResource {
  /**
   *
   * @param proposalId
   * @param authorize_consignment
   * @param lat
   * @param long
   * @param code
   * @param message
   * @return {Promise<ResourceReturnModel>}
   */
  async acceptContract({ proposalId, authorize_consignment, lat, long, code, message }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/contract/accept`,
        payload: { authorize_consignment, lat, long, code, message },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async getContract({ proposalId }) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/contract` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async getContracts(proposalId) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/contracts` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async createContract({ proposalId }) {
    try {
      const response = await this.api.post({ route: `/proposals/${proposalId}/contract` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param ticketId
   * @return {Promise<ResourceReturnModel>}
   */
  async getContractView({ ticketId }) {
    try {
      const response = await this.api.get({ route: `/contract/${ticketId}` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async getDigitalAccountConsentContract(proposalId) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/documents/contrato_conta_digital`,
        params: {
          buffered: true,
        },
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data:
            URL.createObjectURL(ContractController.dataURItoBlob(response.data?.response.path)) ||
            "",
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: "",
        },
      });
    }
  }

  async createDigitalAccountConsentContract(proposalId) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/contract-digital-account`,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response.data?.response || "",
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: "",
        },
      });
    }
  }

  /*

   */
  async signDigitalAccountConsentContract({ proposalId, latitude, longitude }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/contract-digital-account/accept`,
        payload: {
          authorize_consignment: true,
          lat: String(latitude),
          long: String(longitude),
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }
}
