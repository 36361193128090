import { BankLoanConfigState } from "@/store/modules/bankLoanConfig/state";
import { MutationTree } from "vuex";
import getters from "@/store/modules/bankLoanConfig/getters";

export interface BankLoanConfigMutations {
  setBankLoanConfig(state: BankLoanConfigState, payload: any[]): void;
  setPossibleScreens(state: BankLoanConfigState, payload: any[]): void;
  generateAutoBankingConfigByProperty(
    state: BankLoanConfigState,
    payload: { property: string }
  ): void;
  setStorageCards(
    state: BankLoanConfigState,
    payload: {
      code: string;
      name: string;
    }
  ): void;
  setAutomaticBankLoanConfig(state: BankLoanConfigState, payload: boolean): void;
}

const mutations = {
  setBankLoanConfig(state: BankLoanConfigState, payload: []) {
    const hasBankLoanConfiguration = !!payload.length;
    state.hasBankLoanConfig = hasBankLoanConfiguration;

    if (hasBankLoanConfiguration) {
      state.bankLoanConfigs = payload;
    }
  },
  setPossibleScreens(state: BankLoanConfigState, payload: []) {
    state.possibleScreens = payload;
  },
  setStorageCards(state: BankLoanConfigState, payload) {
    state.storageCards = payload;
  },
  setAutomaticBankLoanConfig(state: BankLoanConfigState, payload: boolean) {
    state.hasAutomaticBankingConfig = payload;
  },
  generateAutoBankingConfigByProperty(state: BankLoanConfigState, payload: { property: string }) {
    const { property } = payload;

    const allEquals = getters.hasEqualCodes(state)(property);

    if (allEquals) {
      // Filtra as possíveis telas removendo aquelas relacionadas à propriedade
      state.possibleScreens = state.possibleScreens.filter((item) => !item.code.includes(property));
      // Armazena o card com o código da propriedade
      state.storageCards = {
        ...state.storageCards,
        [property]: { code: state.bankLoanConfigs[0][property].code },
      };
    }
  },
} as MutationTree<BankLoanConfigState> & BankLoanConfigMutations;

export default mutations;
