import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { CancelProposalDTO } from "@/arch/@core/application/DTOs/CancelProposalDataGatewayDTO";
import { CancelProposalDataGateway } from "@/arch/@core/application/protocols/CancelProposalDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class CancelProposalGateway implements CancelProposalDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  cancel(
    proposalId: string | number,
    cancelProposalDto: CancelProposalDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `proposals/${proposalId}/status/cancel`,
      body: cancelProposalDto.toRequest(),
    });
  }

  findCancelCategory(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/cancel-types",
    });
  }

  findCancelCategoryReasons(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/cancel-reasons",
    });
  }

  findCancelReason(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/cancel-type-reasons",
    });
  }
}
