import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { UserProposalDataGateway } from "@/arch/@core/application/protocols/UserProposalDataGateway";
import { UpdateUserParamsDTO } from "@/arch/@core/application/DTOs/UserProposalDataGatewayDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class UserProposalGateway implements UserProposalDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  updateUser({ proposalId, ...userData }: UpdateUserParamsDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.put({
      url: `/proposals/${proposalId}/users`,
      body: userData,
    });
  }
}
