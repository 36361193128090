import AbstractFactory from "@/models/factories/AbstractFactory";
import OccupationModel from "@/models/OccupationModel";
import UserFactory from "@/models/factories/UserFactory";
import CovenantFactory from "@/models/factories/CovenantFactory";
import RoleFactory from "@/models/factories/RoleFactory";
import OccupationFinancialInformationFactory from "@/models/factories/OccupationFinancialInformationFactory";

class OccupationFactory extends AbstractFactory {
  /**
   * @param {object} object
   * @param {object} options
   * @returns {OccupationModel}
   */
  create({ object = {}, options = {} } = {}) {
    const {
      id,
      user_id,
      covenant_id,
      role_id,
      registration_number,
      permanence_time,
      name,
      administration_type,
      admission_date,
      department,
      covenant_password,
      user,
      covenant,
      role,
      financial_information,
    } = object;

    return new OccupationModel({
      id,
      user_id,
      covenant_id,
      role_id,
      registration_number,
      permanence_time,
      name,
      administration_type,
      admission_date,
      department,
      covenant_password,
      user: user ? UserFactory.create({ object: user }) : undefined,
      covenant: covenant ? CovenantFactory.create({ object: covenant }) : undefined,
      role: role ? RoleFactory.create({ object: role }) : undefined,
      financial_information: financial_information
        ? OccupationFinancialInformationFactory.create({ object: financial_information })
        : undefined,
    });
  }
}

export default new OccupationFactory();
