import { IStatusModel } from "@/types/models/Status";

export default class StatusModel {
  public status_group_id: number | null;
  public is_checkpoint: boolean | null;
  public is_blocking: boolean | null;
  public is_alternative: boolean | null;
  public id: number | null;
  public code: string | null;

  constructor({
    code,
    id,
    is_alternative,
    is_blocking,
    is_checkpoint,
    status_group_id,
  }: IStatusModel) {
    this.code = code;
    this.id = id;
    this.is_alternative = is_alternative;
    this.is_blocking = is_blocking;
    this.is_checkpoint = is_checkpoint;
    this.status_group_id = status_group_id;
  }
}
