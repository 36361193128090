import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export interface BankAbcOccupation {
  id: number;
  description: string;
}

export default class BlufinResource extends AbstractOnboardResource {
  async bankAbcOccupations(): Promise<BankAbcOccupation[] | null> {
    try {
      const response = await this.api.get({ route: "/bank-abc/occupations" });
      return response.data.response;
    } catch (e) {
      return null;
    }
  }
}
