import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { TotvsDataGateway } from "@/arch/@core/application/protocols/TotvsDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class TotvsGateway implements TotvsDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getUserData(cpf: string): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "public/totvs/user-data",
      params: { cpf },
    });
  }
}
