export const TYPES = {
  HttpClient: Symbol.for("HttpClient"),
  UserProposalDataGateway: Symbol.for("UserProposalDataGateway"),
  AgreementProposalDataGateway: Symbol.for("AgreementProposalDataGateway"),
  AddressDataGateway: Symbol.for("AddressDataGateway"),
  BankLoanDataGateway: Symbol.for("BankLoanDataGateway"),
  BankDataGateway: Symbol.for("BankDataGateway"),
  CancelProposalDataGateway: Symbol.for("CancelProposalDataGateway"),
  CompanyDataGateway: Symbol.for("CompanyDataGateway"),
  ComplianceDataGateway: Symbol.for("ComplianceDataGateway"),
  ContractDataGateway: Symbol.for("ContractDataGateway"),
  CovenantDataGateway: Symbol.for("CovenantDataGateway"),
  DocumentDataGateway: Symbol.for("DocumentDataGateway"),
  LoanCategoryDataGateway: Symbol.for("LoanCategoryDataGateway"),
  LoanInstallmentDataGateway: Symbol.for("LoanInstallmentDataGateway"),
  LoanReasonDataGateway: Symbol.for("LoanReasonDataGateway"),
  LoanTypeDataGateway: Symbol.for("LoanTypeDataGateway"),
  BlufinDataGateway: Symbol.for("BlufinDataGateway"),
  ToDomainMapper: Symbol.for("ToDomainMapper"),
  DoBlufinLoanSimulation: Symbol.for("DoBlufinLoanSimulation"),
  FindCreditLimit: Symbol.for("FindCreditLimit"),
  ModalityDataGateway: Symbol.for("ModalityDataGateway"),
  OccupationsProposalDataGateway: Symbol.for("OccupationsProposalDataGateway"),
  OccupationsUserDataGateway: Symbol.for("OccupationsUserDataGateway"),
  PendencyDataGateway: Symbol.for("PendencyDataGateway"),
  PersonalReferenceDataGateway: Symbol.for("PersonalReferenceDataGateway"),
  PoliticallyExposedDataGateway: Symbol.for("PoliticallyExposedDataGateway"),
  ProposalDataGateway: Symbol.for("ProposalDataGateway"),
  QuizzDataGateway: Symbol.for("QuizzDataGateway"),
  RefinancingContractDataGateway: Symbol.for("RefinancingContractDataGateway"),
  RoleDataGateway: Symbol.for("RoleDataGateway"),
  SalesDataGateway: Symbol.for("SalesDataGateway"),
  SimulationDataGateway: Symbol.for("SimulationDataGateway"),
  SMSDataGateway: Symbol.for("SMSDataGateway"),
  SpouseDataGateway: Symbol.for("SpouseDataGateway"),
  StateDataGateway: Symbol.for("StateDataGateway"),
  StatusDataGateway: Symbol.for("StatusDataGateway"),
  TermsOfUseDataGateway: Symbol.for("TermsOfUseDataGateway"),
  TotvsDataGateway: Symbol.for("TotvsDataGateway"),
  TemplateDataGateway: Symbol.for("TemplateDataGateway"),
  CreateOrGetQuizUsecase: Symbol.for("CreateOrGetQuizUsecase"),
  SendQuizAnswersUsecase: Symbol.for("SendQuizAnswersUsecase"),
};
