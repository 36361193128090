import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export type Professions = {
  id: number;
  label: string;
  code: string;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
};

export type ProfessionsResponse = {
  response: Professions[];
  message: string;
};

export default class ProfessionsResource extends AbstractOnboardResource {
  async getAllProfessions(bankLoanConfigId: number): Promise<ProfessionsResponse> {
    try {
      const response = await this.api.get({
        route: "/public/trpc/professions.getAll",
        params: {
          input: { "0": { configId: bankLoanConfigId } },
          batch: 1,
        },
      });

      return response.data[0].result.data.response;
    } catch (e) {
      console.error(e);

      return {
        message: "error",
        response: [],
      };
    }
  }
}
