import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class SMSResource extends AbstractOnboardResource {
  /**
   *
   * @param {{}}object
   * @param object.proposalId
   * @param {*}object.messageSendingType
   * @return {Promise<ResourceReturnModel>}
   */
  async sendSMS({ proposalId, messageSendingType }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/messages/send-phone-validation-token`,
        payload: {
          message_sending_type: messageSendingType,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @param code
   * @return {Promise<ResourceReturnModel>}
   */
  async postSMSValidation({ proposalId, code }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/messages/confirmation`,
        payload: { code },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }
}
