import { TYPES } from "@/arch/@core/main";
import HttpClientAdapter from "@/arch/@core/adapter/HttpClientAdapter";
import { UserProposalGateway } from "@/arch/@core/adapter/gateways/UserProposalGateway";
import { AgreementProposalGateway } from "@/arch/@core/adapter/gateways/AgreementProposalGateway";
import { AddressGateway } from "@/arch/@core/adapter/gateways/AddressGateway";
import { BankLoanGateway } from "@/arch/@core/adapter/gateways/BankLoanGateway";
import { BankGateway } from "@/arch/@core/adapter/gateways/BankGateway";
import { CancelProposalGateway } from "@/arch/@core/adapter/gateways/CancelProposalGateway";
import { CompanyGateway } from "@/arch/@core/adapter/gateways/CompanyGateway";
import { ComplianceGateway } from "@/arch/@core/adapter/gateways/ComplianceGateway";
import { ContractGateway } from "@/arch/@core/adapter/gateways/ContractGateway";
import { CovenantGateway } from "@/arch/@core/adapter/gateways/CovenantGateway";
import { DocumentGateway } from "@/arch/@core/adapter/gateways/DocumentGateway";
import { LoanCategoryGateway } from "@/arch/@core/adapter/gateways/LoanCategoryGateway";
import { LoanInstallmentGateway } from "@/arch/@core/adapter/gateways/LoanInstallmentGateway";
import { LoanReasonGateway } from "@/arch/@core/adapter/gateways/LoanReasonGateway";
import { LoanTypeGateway } from "@/arch/@core/adapter/gateways/LoanTypeGateway";
import { BlufinRepository } from "@/arch/new_proposal/blufin/adapter/repository/BlufinRepository";
import { BlufinLoanSimulationMapper } from "@/arch/new_proposal/blufin/application/mapper/BlufinLoanSimulationMapper";
import { DoBlufinLoanSimulation } from "@/arch/new_proposal/blufin/application/usecases/DoBlufinLoanSimulation";
import { FindCreditLimit } from "@/arch/new_proposal/blufin/application/usecases/FindCreditLimit";
import { ModalityGateway } from "@/arch/@core/adapter/gateways/ModalityGateway";
import { OccupationsProposalGateway } from "@/arch/@core/adapter/gateways/OccupationsProposalGateway";
import { OccupationsUserGateway } from "@/arch/@core/adapter/gateways/OccupationsUserGateway";
import { PendencyGateway } from "@/arch/@core/adapter/gateways/PendencyGateway";
import { PersonalReferenceGateway } from "@/arch/@core/adapter/gateways/PersonalReferenceGateway";
import { PoliticallyExposedGateway } from "@/arch/@core/adapter/gateways/PoliticallyExposedGateway";
import { ProposalGateway } from "@/arch/@core/adapter/gateways/ProposalGateway";
import { QuizzGateway } from "@/arch/@core/adapter/gateways/QuizzGateway";
import { RefinancingContractGateway } from "@/arch/@core/adapter/gateways/RefinancingContractGateway";
import { RoleGateway } from "@/arch/@core/adapter/gateways/RoleGateway";
import { SalesGateway } from "@/arch/@core/adapter/gateways/SalesGateway";
import { SimulationGateway } from "@/arch/@core/adapter/gateways/SimulationGateway";
import { SMSGateway } from "@/arch/@core/adapter/gateways/SMSGateway";
import { SpouseGateway } from "@/arch/@core/adapter/gateways/SpouseGateway";
import { StateGateway } from "@/arch/@core/adapter/gateways/StateGateway";
import { StatusGateway } from "@/arch/@core/adapter/gateways/StatusGateway";
import { TermsOfUseGateway } from "@/arch/@core/adapter/gateways/TermsOfUseGateway";
import { TotvsGateway } from "@/arch/@core/adapter/gateways/TotvsGateway";
import { TemplateGateway } from "@/arch/@core/adapter/gateways/TemplateGateway";
import { ContainerModule } from "inversify";

export const gatewayModule = new ContainerModule((bind) => {
  bind(TYPES.HttpClient).toConstantValue(HttpClientAdapter);
  bind(TYPES.UserProposalDataGateway).to(UserProposalGateway);
  bind(TYPES.AgreementProposalDataGateway).to(AgreementProposalGateway);
  bind(TYPES.AddressDataGateway).to(AddressGateway);
  bind(TYPES.BankLoanDataGateway).to(BankLoanGateway);
  bind(TYPES.BankDataGateway).to(BankGateway);
  bind(TYPES.CancelProposalDataGateway).to(CancelProposalGateway);
  bind(TYPES.CompanyDataGateway).to(CompanyGateway);
  bind(TYPES.ComplianceDataGateway).to(ComplianceGateway);
  bind(TYPES.ContractDataGateway).to(ContractGateway);
  bind(TYPES.CovenantDataGateway).to(CovenantGateway);
  bind(TYPES.DocumentDataGateway).to(DocumentGateway);
  bind(TYPES.LoanCategoryDataGateway).to(LoanCategoryGateway);
  bind(TYPES.LoanInstallmentDataGateway).to(LoanInstallmentGateway);
  bind(TYPES.LoanReasonDataGateway).to(LoanReasonGateway);
  bind(TYPES.LoanTypeDataGateway).to(LoanTypeGateway);
  bind(TYPES.BlufinDataGateway).to(BlufinRepository);
  bind(TYPES.ToDomainMapper).to(BlufinLoanSimulationMapper);
  bind(TYPES.DoBlufinLoanSimulation).to(DoBlufinLoanSimulation);
  bind(TYPES.FindCreditLimit).to(FindCreditLimit);
  bind(TYPES.ModalityDataGateway).to(ModalityGateway);
  bind(TYPES.OccupationsProposalDataGateway).to(OccupationsProposalGateway);
  bind(TYPES.OccupationsUserDataGateway).to(OccupationsUserGateway);
  bind(TYPES.PendencyDataGateway).to(PendencyGateway);
  bind(TYPES.PersonalReferenceDataGateway).to(PersonalReferenceGateway);
  bind(TYPES.PoliticallyExposedDataGateway).to(PoliticallyExposedGateway);
  bind(TYPES.ProposalDataGateway).to(ProposalGateway);
  bind(TYPES.QuizzDataGateway).to(QuizzGateway);
  bind(TYPES.RefinancingContractDataGateway).to(RefinancingContractGateway);
  bind(TYPES.RoleDataGateway).to(RoleGateway);
  bind(TYPES.SalesDataGateway).to(SalesGateway);
  bind(TYPES.SimulationDataGateway).to(SimulationGateway);
  bind(TYPES.SMSDataGateway).to(SMSGateway);
  bind(TYPES.SpouseDataGateway).to(SpouseGateway);
  bind(TYPES.StateDataGateway).to(StateGateway);
  bind(TYPES.StatusDataGateway).to(StatusGateway);
  bind(TYPES.TermsOfUseDataGateway).to(TermsOfUseGateway);
  bind(TYPES.TotvsDataGateway).to(TotvsGateway);
  bind(TYPES.TemplateDataGateway).to(TemplateGateway);
});
