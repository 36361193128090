import AbstractUrlHandler from "./AbstractUrlHandler";

class TokenNotFoundHandler extends AbstractUrlHandler {
  accepts({ to }) {
    return !!to.query.externalAuthToken && !to.fullPath.includes("auth-redirect");
  }

  process({ to, next }) {
    try {
      localStorage.setItem("Authorization", to.query.externalAuthToken);

      const { externalAuthToken, ...query } = to.query;

      next({ ...to, query });
    } catch (e) {
      console.error(e);
    }
  }
}

export default new TokenNotFoundHandler();
