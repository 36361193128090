import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export interface SaleMotorola {
  model: string;
  nfe_number: string;
  nfe_serie: string;
  product_value: number;
  imei: string;
  ean: string;
  codigo_xt: string;
  insurance_value: number;
  installment_value: number;
  due_date: string;
  installments_number: number;
}

interface CreateSaleMotorola extends Partial<SaleMotorola> {
  proposalId: number | string;
  shouldQueue: boolean;
}

export default class SalesResource extends AbstractOnboardResource {
  async getSale(proposalId: number | string, shouldQueue = true) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/sale-data`,
        shouldQueue,
      });

      return this.newResourceReturnFactory.create<SaleMotorola>({
        response,
        data: response?.data.response || {},
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<SaleMotorola>({
        response: e,
        data: {},
      });
    }
  }

  async createSale({ proposalId, shouldQueue, ...payload }: CreateSaleMotorola) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/sale-data`,
        payload,
        shouldQueue,
      });

      return this.newResourceReturnFactory.create<SaleMotorola>({
        response,
        data: response?.data?.response || {},
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<SaleMotorola>({
        response: e,
        data: {},
      });
    }
  }
}
