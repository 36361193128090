import { ToDomainMapper } from "@/arch/@core/application/mapper";
import { BlufinSimulatedLoanValues } from "@/arch/new_proposal/blufin/adapter/dtos/BlufinSimulatedLoanValuesDTO";
import { BlufinLoanSimulation } from "@/arch/new_proposal/blufin/domain/entities/BlufinLoanSimulation";
import { inject, injectable, TYPES } from "@/arch/@core/main";
import { BlufinDataGateway } from "@/arch/new_proposal/blufin/application/protocols/BlufinDataGateway";
import { HttpStatusCode } from "@/arch/@core/constants/HttpStatusCode";
import { Usecase, UsecaseResponse } from "@/arch/@core/application/protocols/Usecase";

interface DoBlufinLoanSimulationParams {
  cpf: string;
  requiredValue: number;
}
@injectable()
export class DoBlufinLoanSimulation
  implements Usecase<DoBlufinLoanSimulationParams, Promise<UsecaseResponse<BlufinLoanSimulation>>>
{
  constructor(
    @inject(TYPES.BlufinDataGateway)
    private gateway: BlufinDataGateway,
    @inject(TYPES.ToDomainMapper)
    private mapper: ToDomainMapper<BlufinSimulatedLoanValues[], BlufinLoanSimulation>
  ) {}

  async execute(
    params: DoBlufinLoanSimulationParams
  ): Promise<UsecaseResponse<BlufinLoanSimulation>> {
    const { data, status } = await this.gateway.getLoanSimulation({
      cpf: params.cpf,
      loanValue: params.requiredValue,
    });

    switch (status) {
      case HttpStatusCode.conflict: {
        return {
          error: {
            type: "info",
            message: data.message,
            hideHelpIcon: true,
          },
        };
      }
    }

    return {
      data: this.mapper.toDomain(data.response),
    };
  }
}
