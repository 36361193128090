import { IBrand } from "@/types/whitelabel";
import defaultLoginImage from "@/assets/images/login-image.webp";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";

export const bancoabc: IBrand = {
  hideWhatsappIcon: false,
  baseUrl: "",
  borrowerCanCreateUser: false,
  favicon: "",
  hasSubDomains: false,
  logo: { height: 0, src: "", width: 0 },
  logoOnWhite: { height: 0, src: "", width: 0 },
  minimalLogo: { height: 0, src: "", width: 0 },
  pageTitle: "",
  brand: "bancoabc",
  loginImages: [defaultLoginImage],
  borrowerCanCreateProposal: true,
  bankOrigin: "dinheirow",
  partnerCode: "partner_bancoabc",
  contactEmail: "contato@blufin.com.br",
  contactWhatsappNumber: "+5511996636969",
  contactWhatsappMessage: "Olá, preciso de ajuda!",
  colors: {
    primary: "#18635e",
    secondary: "#239088",
    tertiary: "#fdfbeb",
    quaternary: "#1e7b75",
    quinary: "#7ad06d",
    grey: "#7a7a7a",
    "light-grey": "#fafafa",
    "primary-lighten": "#b7edf0",
    "tertiary-lighten": "#fdfbeb",
  },
  loanValues: {
    min: 300,
    max: 60000,
    cardValues: [5000, 10000, 20000, 30000, 50000],
  },
  logoutUrl: "https://www.bancoabc.com.br/",
  partners: ["blufin"],
  messageSendingType: EMessageSendingType.WhatsApp,

  flowMessages: {
    maritalStatus: {
      title: "Informe o seu estado civil",
      description:
        "A informação correta do seu estado civil é muito importante para darmos mais agilidade ao seu pedido de empréstimo.",
    },
    naturalness: {
      title: "Informe o seu local de nascimento",
      description: "",
    },
    sameBankAccount: {
      title: "Deseja utilizar a mesma conta que está em seu contra-cheque/holerite?",
      description:
        "Você pode utilizar a mesma conta bancária que consta em seu Holerite (contra-cheque) ou cadastrar uma nova conta bancária para receber o seu financiamento.",
    },
    personalReferences: {
      title: "Informe uma referência pessoal",
      description: "",
    },
    person: {
      title: "Você é uma pessoa politicamente exposta?",
      description: "",
    },
    relationship: {
      title: "Você possui algum relacionamento próximo com uma pessoa politicamente exposta?",
      description: "",
    },
    addressConfirmation: {
      title: "Informações de endereço",
      description: "",
    },
    analysis: {
      title:
        "Iremos realizar a análise final da proposta e em até 1 dia útil, retomaremos com o status. Você poderá acompanhar a proposta por aqui ou pelo e-mail que enviaremos.",
      description: `
          Em caso de dúvidas não hesite em entrar em contato pelo e-mail
          <span class="font-weight-bold"> contato@dinheirow.com.br </span>
        `,
    },
    endorsement: {
      title: `Estamos mais perto do que nunca!`,
      description: `Parabéns! A proposta já foi encaminhada ao banco para a análise final. O prazo para o
          recebimento é em média de 2 dias úteis, podendo sofrer variações em função do banco
          parceiro e da disponibilidade do sistema do governo. Porém não se preocupe, estamos
          acompanhando todo o processo e entraremos em contato após a análise final pelo banco.`,
    },
    finalizedAnalysis: {
      title: "Parabéns!",
      description:
        "Sua análise foi aprovada!\n" +
        "Em caso de dúvida, não hesite em entrar em contato com um dos atendentes.",
    },
    awaitTransfer: {
      title: "Empréstimo aprovado!",
      description: `Seu dinheiro será transferido em breve. Em caso de dúvidas não hesite em entrar em contato pelo e-mail <span class="font-weight-bold">contato@dinheirow.com.br</span>`,
    },
  },
  specificRoutes: [
    {
      path: "/",
      name: "Landing",
      meta: { requiresAuth: false },
      component: () => import("@/pages/landing/Landing.vue"),
      alias: ["/bancoarbi"],
    },
  ],
  modalityCodes: ["consignado_privado"],
};
