class GoogleAnalytics {
  private static scriptHTML = `
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-54SPPH2S52"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-54SPPH2S52');
    </script>`;

  public static init() {
    if (import.meta.env.VUE_APP_ENV === "prod") {
      const script = document.createElement("script");
      script.innerHTML = this.scriptHTML;
      document.head.append(script);
    }
  }
}

export default GoogleAnalytics;
