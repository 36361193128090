import AbstractUrlHandler from "./AbstractUrlHandler";

class TokenNotFoundHandler extends AbstractUrlHandler {
  accepts({ to }) {
    return to.path !== "/" && !localStorage.getItem("Authorization") && to.meta.requiresAuth;
  }

  process({ next }) {
    try {
      next("/login");
    } catch (e) {
      console.error(e);
    }
  }
}

export default new TokenNotFoundHandler();
