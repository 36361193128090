import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpStatusCode } from "@/arch/@core/constants/HttpStatusCode";
import { QuizzDataGateway } from "@/arch/@core/application/protocols/QuizzDataGateway";
import { Usecase } from "@/arch/@core/application/protocols/Usecase";
import { QuestionDTO } from "@/arch/@core/application/DTOs/QuestionDTO";

export namespace CreateOrGetQuizDTO {
  export type params = string | number;
  export type result = {
    questions: QuestionDTO[];
    shouldProposalProceed: boolean;
  };
}
@injectable()
export class CreateOrGetQuizUsecase
  implements Usecase<CreateOrGetQuizDTO.params, Promise<CreateOrGetQuizDTO.result>>
{
  constructor(@inject(TYPES.QuizzDataGateway) private quizzGateway: QuizzDataGateway) {}

  async execute(proposalId: CreateOrGetQuizDTO.params): Promise<CreateOrGetQuizDTO.result> {
    const output: CreateOrGetQuizDTO.result = {
      questions: [],
      shouldProposalProceed: false,
    };

    const { status, data } = await this.quizzGateway.getProposalQuiz(proposalId);
    const error = status !== HttpStatusCode.ok || !data.response?.questions.length;

    if (error) {
      if (status === HttpStatusCode.notFound) {
        const { status, data } = await this.quizzGateway.generateQuiz(proposalId);
        const error = status !== HttpStatusCode.ok;

        if (error) {
          switch (status) {
            case HttpStatusCode.badRequest: {
              const { error_code } = data?.response;
              if (error_code === "-101") {
                output.shouldProposalProceed = true;
              }
              return output;
            }

            case HttpStatusCode.forbidden: {
              const { error_code } = data?.response;
              if (error_code === 103) {
                output.shouldProposalProceed = true;
              }
              return output;
            }

            case HttpStatusCode.conflict: {
              const { error_code } = data?.response;
              if (error_code === 101) {
                output.shouldProposalProceed = true;
                return output;
              }
              if (error_code === 102) {
                return this.execute(proposalId);
              }
            }
          }
        }
        output.questions = data.response?.questions;
        return output;
      }
    }

    output.questions = data.response?.questions;
    return output;
  }
}
