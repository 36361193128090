import { BankLoanConfigState } from "@/store/modules/bankLoanConfig/state";
import { GetterTree } from "vuex";

export interface BankLoanConfigGetters {
  hasEqualCodes: (state: BankLoanConfigState) => (prop: string) => {};
}
export const getters = {
  hasEqualCodes: (state: BankLoanConfigState) => (prop: string) => {
    // Verifica se o array não está vazio
    if (state.bankLoanConfigs.length === 0) {
      return false;
    }

    // Obtém o código do primeiro elemento
    const firstItemCode = state.bankLoanConfigs[0][prop].code;

    // Verifica se todos os elementos têm o mesmo código do primeiro elemento
    return state.bankLoanConfigs.every((item) => item[prop].code === firstItemCode);
  },
} as GetterTree<BankLoanConfigState, any> & BankLoanConfigGetters;

export default getters;
