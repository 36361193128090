import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { PersonalReferenceDataGateway } from "@/arch/@core/application/protocols/PersonalReferenceDataGateway";
import { PersonalReferenceDTO } from "@/arch/@core/application/DTOs/PersonalReferenceDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class PersonalReferenceGateway implements PersonalReferenceDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getPersonalReferencesProposal(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/personal-references`,
    });
  }

  setPersonalReferencesProposalUser(
    proposalId: string | number,
    paramsDto: PersonalReferenceDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/personal-references`,
      body: paramsDto,
    });
  }

  updatePersonalReferencesProposalUser(
    proposalId: string | number,
    personalReferenceId: string | number,
    paramsDto: PersonalReferenceDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/personal-references/${personalReferenceId}`,
      body: paramsDto,
    });
  }
}
