import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class RoleResource extends AbstractOnboardResource {
  async index() {
    try {
      const response = await this.api.get({
        route: "/public/roles",
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response || [],
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: [],
          responseObject: e,
        },
      });
    }
  }
}
