import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { inject, TYPES, injectable } from "@/arch/@core/main";
import { LoanTypeDataGateway } from "@/arch/@core/application/protocols/LoanTypeDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class LoanTypeGateway implements LoanTypeDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getLoanTypes(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/public/loan-types",
    });
  }
}
