import actions from "@/store/modules/bankLoanConfig/actions";
import mutations from "@/store/modules/bankLoanConfig/mutations";
import state from "@/store/modules/bankLoanConfig/state";
import getters from "@/store/modules/bankLoanConfig/getters";

export const module = {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
