import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";
import { dialogTypes } from "@components/global/info_dialog/InfoDialogController";
import ResourceReturnFactory from "@/models/factories/ResourceReturnFactory";

type passwordRecoveryParams = {
  email: string;
  messageSendingType?: EMessageSendingType;
};

type sendConfirmationEmailParams = {
  userId: string | number;
  email: string | null;
  showHelpMessage: boolean;
};

type sendContactUsEmailParams = {
  message: string;
  name: string;
  email: string;
  phone: string;
};

interface SendNewDeviceConfirmationParams {
  email: string;
  messageSendingType?: EMessageSendingType;
}

export default class EmailResource extends AbstractOnboardResource {
  async passwordRecovery(payload: passwordRecoveryParams) {
    const response = await this.api.post({
      route: "/public/messages/password-reset",
      payload: {
        message_sending_type: payload.messageSendingType,
        email: payload.email,
      },
    });
    return response.data;
  }

  // TODO separar isso em uma rota de confirmar e outra e de continuar a proposta
  async confirmProposalEmail(proposalId = 0, messageSendingType?: EMessageSendingType) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/messages/register-confirmation`,
        payload: {
          message_sending_type: messageSendingType,
        },
      });

      return {
        error: false,
        responseObject: response,
        data: response?.data?.response || {},
      };
    } catch (e) {
      return {
        error: true,
        responseObject: e,
        data: {},
      };
    }
  }

  async sendConfirmationEmail({ userId, email, showHelpMessage }: sendConfirmationEmailParams) {
    try {
      const response = await this.api.post({
        route: `/users/${userId}/email/register-confirmation`,
      });

      return {
        error: false,
        responseObject: response,
        data: response?.data?.response || {},
        dialogProps: {
          type: "success" as dialogTypes,
          title: "Confirmação de e-mail",
          message: `Enviamos um e-mail para ${email}, com  as instruções necessárias para a validação do seu e-mail`,
        },
      };
    } catch (e) {
      return {
        error: true,
        responseObject: e,
        data: {},
        dialogProps: {
          type: "error" as dialogTypes,
          title: "Confirmação de e-mail",
          message: this.getEmailDialogProps(e.status, email || "", showHelpMessage).message,
        },
      };
    }
  }

  getEmailDialogProps(status: number, email: string, showHelpMessage: boolean) {
    const helpMessage = showHelpMessage
      ? ' Em caso de dúvidas, clique no botão "pedir ajuda" para falar com a nossa equipe.'
      : "";

    const props: { [index: number]: any } = {
      409: {
        message: "Aguarde 30 segundos para uma nova solicitação." + helpMessage,
      },
      422: {
        message: `Email ${email} já confirmado.` + helpMessage,
      },
      500: {
        message:
          `Não conseguimos enviar um e-mail para ${email}. Aguarde 30 segundos para uma nova solicitação.` +
          helpMessage,
      },
    };
    return props[status] || props[500];
  }

  async sendContactUsEmail({ message, name, email, phone }: sendContactUsEmailParams) {
    try {
      const response = await this.api.post({
        route: "/public/email/contact-us",
        payload: { message, name, email, phone },
      });
      return { error: false, ...response };
    } catch (e) {
      return { error: true, ...e };
    }
  }

  async sendNewDeviceConfirmation({ email, messageSendingType }: SendNewDeviceConfirmationParams) {
    try {
      const response = await this.api.post({
        route: "/public/messages/new-device",
        payload: { email, message_sending_type: messageSendingType },
      });

      return { error: false, ...response };
    } catch (e) {
      return { error: true, ...e };
    }
  }

  async confirmEmail({ token }: { token: string }) {
    try {
      const response = await this.api.put({ route: `/public/user/confirm/${token}` });
      return ResourceReturnFactory.create({
        object: { error: false, responseObject: response, data: response.data?.response || {} },
      });
    } catch (e) {
      return ResourceReturnFactory.create({
        object: { error: true, responseObject: e, data: {} },
      });
    }
  }
}
