import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class QuizResource extends AbstractOnboardResource {
  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async generateQuiz({ proposalId }) {
    try {
      const response = await this.api.post({ route: `proposals/${proposalId}/quiz/new` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async getProposalQuiz({ proposalId }) {
    try {
      const response = await this.api.get({ route: `proposals/${proposalId}/quiz` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @param answers
   * @return {Promise<ResourceReturnModel>}
   */
  async postAnswersQuiz({ proposalId, answers = [] }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/quiz/answers`,
        payload: { answers },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }
}
