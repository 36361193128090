import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { RefinancingContractDataGateway } from "@/arch/@core/application/protocols/RefinancingContractDataGateway";
import { RefinancingContractDTO } from "@/arch/@core/application/DTOs/RefinancingContractDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class RefinancingContractGateway implements RefinancingContractDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  signContract(
    proposalId: string | number,
    paramsDto: RefinancingContractDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/refinancing-contract/sign`,
      body: paramsDto.toRequest(),
    });
  }
}
