export default class Geolocation {
  static async getCurrentPosition(): Promise<any> {
    return await new Promise((resolve, reject) => {
      return window.navigator.geolocation.getCurrentPosition(resolve, reject);
    }).catch((error) => {
      const response = { error: true, message: error.message, code: "" };

      switch (error.code) {
        case error.PERMISSION_DENIED:
          response.code = "PERMISSION_DENIED";
          break;
        case error.POSITION_UNAVAILABLE:
          response.code = "POSITION_UNAVAILABLE";
          break;
        case error.TIMEOUT:
          response.code = "TIMEOUT";
          break;
        default:
          response.code = "UNKNOWN_ERROR";
          break;
      }
      return response;
    });
  }
}
