import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class CityResource extends AbstractOnboardResource {
  /**
   *
   * @param stateId
   * @return {Promise<ResourceReturnModel>}
   */
  async getCities({ stateId }) {
    try {
      // const response = await this.api.get({ route: `/public/states/${stateId}/counties` });
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: [],
          responseObject: {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: [],
          responseObject: e,
        },
      });
    }
  }
}
