const state = {
  bankLoanConfigurationStatusGroup: {
    status_group: {
      checkpoint_id: 2,
      code: "configuracao_bancaria",
      description: "Seleção da configuração bancária",
      id: 7,
      title: "Configuração Bancária",
    },
    statuses: [
      {
        code: "configuracao_bancaria",
        id: 1,
        is_alternative: false,
        is_blocking: false,
        is_checkpoint: false,
      },
    ],
  },
  borrower: [
    {
      status_group: {
        checkpoint_id: 1,
        code: "valores_e_dados",
        description: "Status do Início do fluxo",
        id: 6,
        title: "Convênio e Valores",
      },
      statuses: [
        {
          code: "simulacao",
          id: 1,
          is_alternative: false,
          is_blocking: false,
          is_checkpoint: false,
          status_group_id: 6,
        },
      ],
    },
    {
      status_group: {
        checkpoint_id: 1,
        code: "dados_basicos",
        description: "Status que informam os dados básicos do Usuário",
        id: 7,
        order: 200,
        title: "Dados Básicos",
      },
      statuses: [],
    },
    {
      status_group: {
        checkpoint_id: 1,
        code: "documentacao",
        description: "Status que envolvem o upload de documentos do Usuário",
        id: 8,
        order: 300,
        title: "Documentação",
      },
      statuses: [],
    },
    {
      status_group: {
        checkpoint_id: 1,
        code: "pre_analise",
        description: "Grupos de Status que estão contidos até o envio do Holerite",
        id: 1,
        title: "Pré Análise",
      },
      statuses: [],
    },
  ],
  admin: [
    {
      status_group: {
        checkpoint_id: 1,
        code: "admin_user_select",
        description: "Seleção do usuário da proposta",
        id: 7,
        title: "Usuário da proposta",
      },
      statuses: [
        {
          code: "admin_user_select",
          id: 1,
          is_alternative: false,
          is_blocking: false,
          is_checkpoint: false,
        },
      ],
    },
    {
      status_group: {
        checkpoint_id: 1,
        code: "valores_e_dados",
        description: "Status do Início do fluxo",
        id: 6,
        title: "Convênio e Valores",
        hidden: false,
      },
      statuses: [
        {
          code: "simulacao",
          id: 1,
          is_alternative: false,
          is_blocking: false,
          is_checkpoint: false,
          status_group_id: 6,
        },
      ],
    },
    {
      status_group: {
        checkpoint_id: 1,
        code: "dados_basicos",
        description: "Status que informam os dados básicos do Usuário",
        id: 7,
        order: 200,
        title: "Dados Básicos",
      },
      statuses: [],
    },
    {
      status_group: {
        checkpoint_id: 1,
        code: "documentacao",
        description: "Status que envolvem o upload de documentos do Usuário",
        id: 8,
        order: 300,
        title: "Documentação",
      },
      statuses: [],
    },
    {
      status_group: {
        checkpoint_id: 1,
        code: "pre_analise",
        description: "Grupos de Status que estão contidos até o envio do Holerite",
        id: 1,
        title: "Pré Análise",
      },
      statuses: [],
    },
  ],
};

export type statusGroupState = typeof state;

export default state;
