import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { inject, injectable, TYPES } from "@/arch/@core/main";
import { LoanCategoryDataGateway } from "@/arch/@core/application/protocols/LoanCategoryDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class LoanCategoryGateway implements LoanCategoryDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  findLoanCategories(): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/public/loan-categories",
    });
  }
}
