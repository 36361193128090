import BankLoanConfigStatusFactory from "@/models/factories/BankLoanConfigStatusFactory";
import ProposalFactory from "@/models/factories/ProposalFactory";

const actions = {
  setDataProposal({ commit }, payload) {
    commit("SET_DATA_PROPOSAL", payload);
  },

  setStatusProposal({ commit }, payload) {
    commit("SET_STATUS_PROPOSAL", payload);
  },

  async statusProposalHandler({ commit, rootState }, proposalId) {
    const { onboardApi } = rootState.OnboardApi;
    const { data } = await onboardApi.resources.status.getStatusProposal({
      proposalId,
    });
    const bankLoanConfigStatus = BankLoanConfigStatusFactory.create({ object: data });
    commit("SET_STATUS_PROPOSAL", bankLoanConfigStatus);
  },

  async proposalDataHandler({ commit, rootState }, proposalId) {
    const { onboardApi } = rootState.OnboardApi;
    const { data } = await onboardApi.resources.proposal.getProposal({
      proposalId,
      includes:
        "bank_loan_configurations,created_by_user,proposal_details,proposal_detail_simulated",
    });
    const proposal = ProposalFactory.create(data);
    commit("SET_DATA_PROPOSAL", proposal);
  },
};

export default actions;
