import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { UserBankAccountDTO } from "@/arch/@core/application/DTOs/bankDataGatewayDTO";
import { BankDataGateway } from "@/arch/@core/application/protocols/BankDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class BankGateway implements BankDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  getBanks(proposalId: number | string): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/public/banks",
      params: {
        proposal_id: proposalId,
      },
    });
  }

  usersBankAccounts(userBankAccountDto: UserBankAccountDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: "/users/bank-accounts",
      body: userBankAccountDto.toRequest(),
    });
  }

  adminCreate(
    userId: number | string,
    userBankAccountDto: UserBankAccountDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/users/${userId}/bank-accounts`,
      body: userBankAccountDto.toRequest(),
    });
  }

  getProposalBankAccount(proposalId: number | string): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/bank-account`,
    });
  }

  updateProposalBankAccount(
    proposalId: number | string,
    userBankAccountDto: UserBankAccountDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.put({
      url: `/proposals/${proposalId}/bank-accounts`,
      body: userBankAccountDto.toRequest(),
    });
  }

  createProposalBankAccount(
    proposalId: number | string,
    userBankAccountDto: UserBankAccountDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/bank-accounts`,
      body: userBankAccountDto.toRequest(),
    });
  }
}
