import { Tabs } from "@components/dashboard/home/borrower/BorrowerHomeController";
import OnboardApiError from "@/errors/OnboardApiError";

export type cancelProposalAction = (proposalId: string | number) => void;

const actions = {
  async getProposalsByActiveTab({ commit, rootState, state }: any, activeTabPayload?: Tabs) {
    const { onboardApi } = rootState.OnboardApi;
    const { proposals } = state.borrower;
    const activeTab = activeTabPayload?.length ? activeTabPayload : state.borrower.activeTab;

    const { responseObject, data = [] } = await onboardApi.resources.proposal.getProposals({
      page: activeTab === "active" ? 1 : proposals[activeTab].page,
      length: activeTab === "active" ? 1 : 3,
      includes: "bank_loan_configurations",
      status_group: activeTab === "active" ? "aberto" : activeTab,
      order_by: "updated_at",
    });

    commit("setProposals", {
      proposals: data,
      numberOfProposals: responseObject.headers?.numberproposals || "0",
    });
  },
  async cancelProposal({ dispatch, rootState }: any, proposalId: string | number) {
    const { onboardApi } = rootState.OnboardApi;
    const { error, response } = await onboardApi.resources.proposal.cancelProposalById(proposalId);
    if (error) throw new OnboardApiError(response);
    await dispatch("getProposalsByActiveTab");
  },
};

export default actions;
