import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { statusGroupState } from "@/store/modules/statusGroup/state";

const actions = {
  async updateStatusGroupByBankLoanConfiguration({
    commit,
    rootState,
  }: ActionContext<statusGroupState, RootState>) {
    const { hasAutomaticBankingConfig } = rootState.BankLoanConfig;

    if (hasAutomaticBankingConfig) {
      commit("hideCovenantAndValues", true);
    } else {
      commit("hideCovenantAndValues", false);
    }
  },
};

export default actions;
