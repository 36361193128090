import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import {
  IPendenciesGrouped,
  IPendency,
  IPendencyGroup,
} from "@/types/resources/onboard/PendencyResource";

interface ResolveFieldPendencyParams {
  pendencyId: number;
  newValue: string | number;
}

type TFilePendency = FormData;

export default class PendencyResource extends AbstractOnboardResource {
  async index(proposalId: number | string) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/pendencies`,
      });

      return this.newResourceReturnFactory.create<IPendenciesGrouped>({
        response,
        data: response.data?.response || {},
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<IPendenciesGrouped>({
        response: e,
        data: {},
      });
    }
  }

  async indexFlattedPendencies(proposalId: number | string) {
    const {
      data: { partner = [], borrower = [] },
      response,
    } = await this.index(proposalId);

    return this.newResourceReturnFactory.create<{ borrower: IPendency[]; partner: IPendency[] }>({
      response,
      data: {
        borrower: PendencyResource.flatPendingGroup(borrower),
        partner: PendencyResource.flatPendingGroup(partner),
      },
    });
  }

  private static flatPendingGroup(group: IPendencyGroup[]) {
    return (
      group.map((item) => item.sub_groups.map((subGroup) => subGroup.pendencies)).flat(2) || []
    );
  }

  async resolveFieldPendency({ pendencyId, newValue }: ResolveFieldPendencyParams) {
    try {
      const response = await this.api.post({
        route: `/resolve-field-pending/${pendencyId}`,
        payload: {
          new_value: newValue,
        },
      });
      return this.newResourceReturnFactory.create({
        response,
        data: {},
      });
    } catch (e) {
      return this.newResourceReturnFactory.create({
        response: e,
        data: {},
      });
    }
  }

  async resolveFilePendency(formData: TFilePendency, pendencyId: number) {
    try {
      const response = await this.api.post({
        route: `/resolve-file-pending/${pendencyId}`,
        payload: formData,
      });
      return this.newResourceReturnFactory.create({
        response,
        data: {},
      });
    } catch (e) {
      return this.newResourceReturnFactory.create({
        response: e,
        data: {},
      });
    }
  }
}
