import { inject, injectable, TYPES } from "@/arch/@core/main";
import { HttpStatusCode } from "@/arch/@core/constants/HttpStatusCode";
import { Usecase } from "@/arch/@core/application/protocols/Usecase";
import { QuizzGateway } from "@/arch/@core/adapter/gateways/QuizzGateway";

export namespace SendQuizAnswersDTO {
  export type params = {
    proposalId: string | number;
    answersIds: number[];
  };
  export type result = {
    shouldProposalProceed: boolean;
    errorMessage: string;
    quizCreated: boolean;
  };
}

@injectable()
export class SendQuizAnswersUsecase
  implements Usecase<SendQuizAnswersDTO.params, Promise<SendQuizAnswersDTO.result>>
{
  constructor(@inject(TYPES.QuizzDataGateway) private gateway: QuizzGateway) {}

  async execute(dto: SendQuizAnswersDTO.params): Promise<SendQuizAnswersDTO.result> {
    const output: SendQuizAnswersDTO.result = {
      shouldProposalProceed: false,
      errorMessage: "",
      quizCreated: false,
    };
    const { data, status } = await this.gateway.postAnswersQuiz(dto.proposalId, dto.answersIds);
    const errorCode = data?.response?.error_code;

    switch (status) {
      case HttpStatusCode.ok: {
        output.quizCreated = true;
        return output;
      }
      case HttpStatusCode.badRequest: {
        if (errorCode === "-307") {
          output.shouldProposalProceed = true;
          return output;
        }
        output.errorMessage = data?.message;
        return output;
      }
      case HttpStatusCode.notFound: {
        if (errorCode === 101) output.errorMessage = data?.message;
        return output;
      }
      case HttpStatusCode.conflict: {
        if (errorCode === 101) output.shouldProposalProceed = true;
        if (errorCode === 103) output.errorMessage = data?.message;
        if (errorCode === 104) output.errorMessage = data?.message;
        return output;
      }
    }

    return output;
  }
}
