import { container } from "@/arch/@core/main/ioc/ContainerRegister";
import HttpClientAdapter from "@/arch/@core/adapter/HttpClientAdapter";
import { TYPES } from "@/arch/@core/main";
import { UserProposalDataGateway } from "@/arch/@core/application/protocols/UserProposalDataGateway";
import { AgreementProposalDataGateway } from "@/arch/@core/application/protocols/AgreementProposalDataGateway";
import { AddressDataGateway } from "@/arch/@core/application/protocols/AddressDataGateway";
import { BankLoanDataGateway } from "@/arch/@core/application/protocols/BankLoanRepository";
import { BankDataGateway } from "@/arch/@core/application/protocols/BankDataGateway";
import { CancelProposalGateway } from "@/arch/@core/adapter/gateways/CancelProposalGateway";
import { CompanyDataGateway } from "@/arch/@core/application/protocols/CompanyDataGateway";
import { ComplianceDataGateway } from "@/arch/@core/application/protocols/ComplianceDataGateway";
import { ContractDataGateway } from "@/arch/@core/application/protocols/ContractDataGateway";
import { CovenantDataGateway } from "@/arch/@core/application/protocols/CovenantDataGateway";
import { DocumentDataGateway } from "@/arch/@core/application/protocols/DocumentDataGateway";
import { LoanCategoryDataGateway } from "@/arch/@core/application/protocols/LoanCategoryDataGateway";
import { LoanInstallmentDataGateway } from "@/arch/@core/application/protocols/LoanInstallmentDataGateway";
import { LoanReasonDataGateway } from "@/arch/@core/application/protocols/LoanReasonDataGateway";
import { LoanTypeDataGateway } from "@/arch/@core/application/protocols/LoanTypeDataGateway";
import { BlufinDataGateway } from "@/arch/new_proposal/blufin/application/protocols/BlufinDataGateway";
import { DoBlufinLoanSimulation } from "@/arch/new_proposal/blufin/application/usecases/DoBlufinLoanSimulation";
import { FindCreditLimit } from "@/arch/new_proposal/blufin/application/usecases/FindCreditLimit";
import { ModalityDataGateway } from "@/arch/@core/application/protocols/ModalityDataGateway";
import { OccupationsProposalDataGateway } from "@/arch/@core/application/protocols/OccupationsProposalDataGateway";
import { OccupationsUserDataGateway } from "@/arch/@core/application/protocols/OccupationsUserDataGateway";
import { PendencyDataGateway } from "@/arch/@core/application/protocols/PendencyDataGateway";
import { PersonalReferenceDataGateway } from "@/arch/@core/application/protocols/PersonalReferenceDataGateway";
import { PoliticallyExposedDataGateway } from "@/arch/@core/application/protocols/PoliticallyExposedDataGateway";
import { ProposalDataGateway } from "@/arch/@core/application/protocols/ProposalDataGateway";
import { QuizzDataGateway } from "@/arch/@core/application/protocols/QuizzDataGateway";
import { RefinancingContractDataGateway } from "@/arch/@core/application/protocols/RefinancingContractDataGateway";
import { RoleDataGateway } from "@/arch/@core/application/protocols/RoleDataGateway";
import { SalesDataGateway } from "@/arch/@core/application/protocols/SalesDataGateway";
import { SimulationGateway } from "@/arch/@core/adapter/gateways/SimulationGateway";
import { SMSDataGateway } from "@/arch/@core/application/protocols/SMSDataGateway";
import { SpouseDataGateway } from "@/arch/@core/application/protocols/SpouseDataGateway";
import { StateDataGateway } from "@/arch/@core/application/protocols/StateDataGateway";
import { StatusGateway } from "@/arch/@core/adapter/gateways/StatusGateway";
import { TermsOfUseDataGateway } from "@/arch/@core/application/protocols/TermsOfUseDataGateway";
import { TotvsDataGateway } from "@/arch/@core/application/protocols/TotvsDataGateway";
import { TemplateDataGateway } from "@/arch/@core/application/protocols/TemplateDataGateway";

export const gatewayInstances = {
  httpClientAdapter: container.get<typeof HttpClientAdapter>(TYPES.HttpClient),
  userProposalRepository: container.get<UserProposalDataGateway>(TYPES.UserProposalDataGateway),
  agreementProposalGateway: container.get<AgreementProposalDataGateway>(
    TYPES.AgreementProposalDataGateway
  ),
  addressGateway: container.get<AddressDataGateway>(TYPES.AddressDataGateway),
  bankLoanGateway: container.get<BankLoanDataGateway>(TYPES.BankLoanDataGateway),
  bankGateway: container.get<BankDataGateway>(TYPES.BankDataGateway),
  cancelProposalGateway: container.get<CancelProposalGateway>(TYPES.CancelProposalDataGateway),
  companyGateway: container.get<CompanyDataGateway>(TYPES.CompanyDataGateway),
  complianceGateway: container.get<ComplianceDataGateway>(TYPES.ComplianceDataGateway),
  contractGateway: container.get<ContractDataGateway>(TYPES.ContractDataGateway),
  covenantGateway: container.get<CovenantDataGateway>(TYPES.CovenantDataGateway),
  documentGateway: container.get<DocumentDataGateway>(TYPES.DocumentDataGateway),
  loanCategoryGateway: container.get<LoanCategoryDataGateway>(TYPES.LoanCategoryDataGateway),
  loanInstallmentGateway: container.get<LoanInstallmentDataGateway>(
    TYPES.LoanInstallmentDataGateway
  ),
  loanReasonGateway: container.get<LoanReasonDataGateway>(TYPES.LoanReasonDataGateway),
  loanTypeGateway: container.get<LoanTypeDataGateway>(TYPES.LoanTypeDataGateway),
  blufinGateway: container.get<BlufinDataGateway>(TYPES.BlufinDataGateway),
  doBlufinLoanSimulationUsecase: container.get<DoBlufinLoanSimulation>(
    TYPES.DoBlufinLoanSimulation
  ),
  findCreditLimit: container.get<FindCreditLimit>(TYPES.FindCreditLimit),
  modalityGateway: container.get<ModalityDataGateway>(TYPES.ModalityDataGateway),
  occupationsProposalGateway: container.get<OccupationsProposalDataGateway>(
    TYPES.OccupationsProposalDataGateway
  ),
  occupationsUserGateway: container.get<OccupationsUserDataGateway>(
    TYPES.OccupationsUserDataGateway
  ),
  pendencyGateway: container.get<PendencyDataGateway>(TYPES.PendencyDataGateway),
  personalReferenceGateway: container.get<PersonalReferenceDataGateway>(
    TYPES.PersonalReferenceDataGateway
  ),
  politicallyExposedGateway: container.get<PoliticallyExposedDataGateway>(
    TYPES.PoliticallyExposedDataGateway
  ),
  proposalGateway: container.get<ProposalDataGateway>(TYPES.ProposalDataGateway),
  quizzGateway: container.get<QuizzDataGateway>(TYPES.QuizzDataGateway),
  refinancingContractGateway: container.get<RefinancingContractDataGateway>(
    TYPES.RefinancingContractDataGateway
  ),
  roleGateway: container.get<RoleDataGateway>(TYPES.RoleDataGateway),
  salesGateway: container.get<SalesDataGateway>(TYPES.SalesDataGateway),
  simulationGateway: container.get<SimulationGateway>(TYPES.SimulationDataGateway),
  smsGateway: container.get<SMSDataGateway>(TYPES.SMSDataGateway),
  spouseGateway: container.get<SpouseDataGateway>(TYPES.SpouseDataGateway),
  stateGateway: container.get<StateDataGateway>(TYPES.StateDataGateway),
  statusGateway: container.get<StatusGateway>(TYPES.StatusDataGateway),
  termsOfUseGateway: container.get<TermsOfUseDataGateway>(TYPES.TermsOfUseDataGateway),
  totvsGateway: container.get<TotvsDataGateway>(TYPES.TotvsDataGateway),
  templateGateway: container.get<TemplateDataGateway>(TYPES.TemplateDataGateway),
};
