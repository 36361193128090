import { HttpStatusCode } from "@/arch/@core/constants/HttpStatusCode";
import { GlobalErrorPayload } from "@/store/modules/globalError/state";
import { ErrorProps, FeedbackMessages } from "@/utils/FeedbackMessages";

type FeedBackMap = { status: number } & ErrorProps;

const actions = {
  chooseGlobalError({ commit }: any, payload: GlobalErrorPayload) {
    const errorMessage = () => {
      const dynamicFeedback = {
        status: payload.status,
        ...FeedbackMessages.dynamicFeedback({
          title: payload?.title?.length ? payload.title : "",
          message: payload.message,
        }),
      };

      const feedBackMap: { [key: number]: FeedBackMap } = {
        [HttpStatusCode.forbidden]: {
          status: payload.status,
          ...FeedbackMessages.permissionDenied(payload.message),
        },
        [HttpStatusCode.unauthorized]: {
          status: payload.status,
          ...FeedbackMessages.expiredSession(),
        },
        [HttpStatusCode.serverError]: {
          status: payload.status,
          ...FeedbackMessages.serverError(false),
        },
        [HttpStatusCode.conflict]: dynamicFeedback,
        // [HttpStatusCode.failedDependency]: dynamicFeedback,
        [HttpStatusCode.notAcceptable]: dynamicFeedback,
      };

      return feedBackMap[payload.status];
    };

    if (errorMessage()) commit("setGlobalError", { ...errorMessage() });
  },
};
export default actions;
