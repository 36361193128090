import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

interface IAuthenticate {
  url: string;
  user: string;
  password: string;
  userLabel: string;
  passwordLabel: string;
  optionalLoginOptions?: {};
}

type verifyPasswordRecoveryCodeParams = {
  code: string | number;
  token: string;
};

type recoveryPasswordParams = {
  token: string;
  newPassword: string;
};

type confirmNewDeviceSmsCodeParams = {
  code: string;
  uuid: string;
};

export default class AuthResource extends AbstractOnboardResource {
  async doLoginJSON({
    url,
    user,
    password,
    userLabel,
    passwordLabel,
    optionalLoginOptions,
  }: IAuthenticate) {
    const defaultLoginObject = {
      [userLabel]: user,
      [passwordLabel]: password,
    };
    const loginObject = Object.assign(defaultLoginObject, optionalLoginOptions);
    const response = await this.api.post({
      route: url,
      payload: { ...loginObject },
    });
    localStorage.setItem("Authorization", response.data.response.token);
    return response.data;
  }

  async recoveryPassword({ token, newPassword }: recoveryPasswordParams) {
    try {
      const response = await this.api.put({
        route: `/public/user/reset-password/${token}`,
        payload: { password: newPassword },
      });
      return { error: false, ...response };
    } catch (e) {
      return { error: true, ...e };
    }
  }

  async verifyPasswordRecoveryCode({ code, token }: verifyPasswordRecoveryCodeParams) {
    try {
      const response = await this.api.post({
        route: "/public/messages/reset-password/confirmation",
        payload: { code, token },
      });
      return { error: false, ...response };
    } catch (e) {
      return { error: true, ...e };
    }
  }

  async confirmNewDeviceSmsCode({ code, uuid }: confirmNewDeviceSmsCodeParams) {
    try {
      const response = await this.api.post({
        route: "/public/messages/new-device/confirmation",
        payload: { code, uuid },
      });
      return { error: false, ...response };
    } catch (e) {
      return { error: true, ...e };
    }
  }

  async oauthAuthorize({
    proposal_id,
    authorization_code,
  }: {
    proposal_id: string | number;
    authorization_code: string;
  }) {
    try {
      const response = await this.api.post({
        route: "/oauth/authorize",
        payload: { proposal_id, authorization_code },
      });
      return { error: false, ...response };
    } catch (e) {
      return { error: true, ...e };
    }
  }
}
