import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";
import { ProposalDTO, ProposalsDTO } from "@/arch/@core/application/DTOs/ProposalDTO";
import { ProposalDataGateway } from "@/arch/@core/application/protocols/ProposalDataGateway";
import { GeoLocalizationDTO } from "@/arch/@core/application/DTOs/GeoLocalizationDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";
@injectable()
export class ProposalGateway implements ProposalDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  adminCreate(userId: string | number, paramsDto: ProposalDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/users/${userId}/proposals`,
      body: paramsDto.toRequest(),
    });
  }

  calculateArbiLimitValues(
    proposalId: string | number,
    available_balance_amount: number
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/arbi-limit-values`,
      body: {
        available_balance_amount,
      },
    });
  }

  ciaDoCreditoProposal(cpf: string): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: "/proposal-ciadocredito",
      body: {
        cpf,
      },
    });
  }

  contractSummaryConfirmation(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/contract-summary-confirmation`,
    });
  }

  create(paramsDto: ProposalDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: "/proposals",
      body: paramsDto.toRequest(),
    });
  }

  geolocationConfirmValuesCompliance(
    proposalId: string | number,
    paramsDto: GeoLocalizationDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/geolocation`,
      body: paramsDto.toRequest(),
    });
  }

  getArbiLimitValues(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/arbi-limit-values`,
    });
  }

  getProposal(proposalId: string | number, includes: string): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}`,
      params: { includes },
    });
  }

  getProposalDetails(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/proposal-details`,
    });
  }

  getProposalFlow(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/status-flow`,
    });
  }

  getProposals(includes: string, paramsDto: ProposalsDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/proposals",
      params: paramsDto.toRequest(),
    });
  }

  getSummary(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposal/${proposalId}/summary`,
    });
  }

  proposalValuesConfirmation(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/values-confirmation`,
    });
  }

  resendEmailWithContract(
    proposalId: string | number,
    message_sending_type: EMessageSendingType.Email
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/messages/resend-contract`,
      body: { message_sending_type },
    });
  }

  simulateFGTSProposal(
    proposalId: string | number,
    required_value: number
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/simulate-fgts`,
      body: { required_value },
    });
  }

  updateProposal(
    proposalId: string | number,
    paramsDto: ProposalDTO
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.put({
      url: `/proposals/${proposalId}`,
      body: paramsDto.toRequest(),
    });
  }
}
