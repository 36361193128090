import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class DocumentResource extends AbstractOnboardResource {
  async createDocument({ formData, onProgress, proposalId, scope, shouldQueue = true }: any) {
    try {
      const config = {
        onUploadProgress(progressEvent: any) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (onProgress) onProgress(percentCompleted, scope);
          return percentCompleted;
        },
      };
      const response = await this.api.post({
        route: `/proposals/${proposalId}/documents`,
        payload: formData,
        config,
        shouldQueue,
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async signContract(proposalId: number | string, location?: any) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/contract/sign`,
        payload: {
          lat: location?.latitude,
          long: location?.longitude,
        },
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async find({ proposalId = 0, status = true, ignoreUpload = false, required = true }) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/documents-configuration`,
        params: {
          status,
          ignore_upload_document: ignoreUpload,
          required,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }

  /**
   * @param {{}} object
   * @param {boolean} object.ignoreUpload
   * @param {number | string} object.proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async findAllRequiredAndNonRequiredDocuments({ proposalId = 0, ignoreUpload = false }) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/documents-configuration`,
        params: {
          ignore_upload_document: ignoreUpload,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }

  async getAllUploadedDocuments(proposalId = 0) {
    const [requiredDocuments, nonRequiredDocuments] = await Promise.all([
      this.find({ proposalId, status: false, ignoreUpload: true, required: true }),
      this.find({ proposalId, status: false, ignoreUpload: true, required: false }),
    ]);
    return [...requiredDocuments.data, ...nonRequiredDocuments.data];
  }

  async getCurrentDocument({
    proposalId = 0,
    documentCode = "",
    buffered = false,
    shouldQueue = true,
  }) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/documents/${documentCode}?can_reuse=false`,
        params: { buffered },
        shouldQueue,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param {string | number} proposalId
   * @return {Promise<{data: [], error:boolean, responseObject: any}>}
   */
  async getAllDocumentsFromProposal(proposalId = 0) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/documents`,
        shouldQueue: true,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }

  async sendUploadDocumentLink({ proposalId = 0, messageSendingType = "" }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/send-document-url`,
        payload: {
          message_sending_type: messageSendingType,
        },
        shouldQueue: true,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async confirmDocumentMobile({ code = "", proposalId = 0 }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/messages/document-upload/confirmation`,
        payload: { code },
      });
      return { error: false, ...response };
    } catch (e) {
      return { error: true, ...e };
    }
  }
}
