<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <InfoDialog />
    <Snackbar />
    <GlobalLoading />
    <CookiesValidation />
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import GoogleAnalytics from "@/services/GoogleAnalytics";
import { MetaInfo } from "vue-meta";
import { IBrand } from "@/types/whitelabel";
import { Getter } from "vuex-class";
import { HttpStatusCode } from "@/arch/@core/constants/HttpStatusCode";

@Component({
  name: "App",
  components: {
    GlobalLoading: () => import("@/components/global/loading/GlobalLoading.vue"),
    Snackbar: () => import("@/components/global/snackbar/Snackbar.vue"),
    InfoDialog: () => import("@/components/global/info_dialog/InfoDialog.vue"),
    CookiesValidation: () => import("@/components/global/cookies_validation/CookiesValidation.vue"),
  },
  metaInfo(this: App): MetaInfo {
    return {
      title: this.currentBrand?.pageTitle || "Dinheirow",
      link: [
        {
          rel: "icon",
          href: this.currentBrand?.favicon || "favicon.ico",
        },
      ],
    };
  },
})
export default class App extends Vue {
  @Getter("whiteLabel") currentBrand!: IBrand;
  private unsubscribe: Function = () => {};

  get theme(): string {
    return this.$vuetify.theme.dark ? "dark" : "light";
  }

  beforeCreate() {
    document?.querySelector("[rel='icon']")?.remove();
    GoogleAnalytics.init();
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setGlobalError") {
        this.$bus.$emit("openDialog", {
          type: "error",
          title: state.GlobalError.currentGlobalError.title,
          message: state.GlobalError.currentGlobalError.message,
        });

        if (state.GlobalError.currentGlobalError.status === HttpStatusCode.unauthorized) {
          localStorage.removeItem("Authorization");
          this.$router.push("/Login");
        }
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

root {
  font-size: 100%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
  only screen and (-o-min-device-pixel-ratio: 13/10),
  only screen and (min-resolution: 120dpi) {
  :root {
    font-size: 85%;
  }
}

@media (max-width: 430px) {
  :root {
    font-size: 85%;
  }

  h1,
  h2 {
    font-size: 2rem;
    line-height: initial;
  }
}

body {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity, transform;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  transform: translateY(-2em);
  opacity: 0;
}
</style>
