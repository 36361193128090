import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class BillingResource extends AbstractOnboardResource {

  async getConsolidatedInvoice({ proposalId = 0 }: {proposalId: number | string;}) {
    try {
      const response = await this.api.get({
        route: `/billing/payment-slip-booklet/proposal/${proposalId}`,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }
}
