import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class PoliticallyExposedResource extends AbstractOnboardResource {
  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async getPPE({ proposalId }) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/pep` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }

  async getPPETypes() {
    try {
      const response = await this.api.get({ route: `/pep-types` });
      return response?.data?.response || [];
    } catch (e) {
      return [];
    }
  }

  /**
   *
   * @param proposalId
   * @param cpf
   * @param is_close_relationship
   * @param is_pep
   * @param name
   * @param type
   * @return {Promise<ResourceReturnModel>}
   */
  async createPPE({ proposalId, cpf = null, is_close_relationship, type, is_pep, name = null }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/pep`,
        payload: { cpf, is_close_relationship, is_pep, name, pep_type_id: type },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }
}
