import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { CompanyDataGateway } from "@/arch/@core/application/protocols/CompanyDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class CompanyGateway implements CompanyDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  find(companyCnpj: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/companies/${companyCnpj}`,
    });
  }

  findBankAccount(companyId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/companies/${companyId}/bank-account?includes=banks`,
    });
  }
}
