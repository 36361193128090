const mutations = {
  /**
   *
   * @param state
   * @param {UserModel} payload
   * @constructor
   */
  SET_DATA_USER(state, payload) {
    const groups = payload.groups.length ? payload.groups : state.user.groups;
    Object.assign(state, {
      user: { ...state.user, ...payload, groups },
    });
  },
};

export default mutations;
