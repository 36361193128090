/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
class AbstractRequestHandler {
  accepts(_requestObject) {}

  process(_requestObject) {}
}

export default AbstractRequestHandler;

// Classe abstrata serve para definir os metodos e o contrutor que as classes filhas deverão ter
