import { IStatusGroupModel } from "@/types/models/StatusGroup";

export default class StatusGroupModel {
  public checkpoint_id: number | null;
  public code: string | null;
  public description: string | null;
  public id: number | null;
  public order: number | null;
  public title: string | null;

  constructor({ checkpoint_id, code, description, id, order, title }: IStatusGroupModel) {
    this.checkpoint_id = checkpoint_id;
    this.code = code;
    this.description = description;
    this.id = id;
    this.order = order;
    this.title = title;
  }
}
