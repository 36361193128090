import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { SalesDataGateway } from "@/arch/@core/application/protocols/SalesDataGateway";
import { SaleDTO } from "@/arch/@core/application/DTOs/SaleDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class SalesGateway implements SalesDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  createSale(proposalId: string | number, paramsDto: SaleDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/proposals/${proposalId}/sale-data`,
      body: paramsDto.toRequest(),
    });
  }

  getSale(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/sale-data`,
    });
  }
}
