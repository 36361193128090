import { inject, TYPES, injectable } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { SpouseDataGateway } from "@/arch/@core/application/protocols/SpouseDataGateway";
import { SpouseDTO } from "@/arch/@core/application/DTOs/SpouseDTO";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class SpouseGateway implements SpouseDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  createSpouse(paramsDto: SpouseDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: "/spouses",
      body: paramsDto.toRequest(),
    });
  }

  editSpouse(spouseId: string | number, paramsDto: SpouseDTO): Promise<GatewayResponse<any>> {
    return this.httpClient.put({
      url: `/spouses/${spouseId}`,
      body: paramsDto.toRequest(),
    });
  }

  getSpouseByUser(user_id: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: "/spouses",
      params: {
        user_id,
      },
    });
  }
}
