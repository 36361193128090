import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class CovenantResource extends AbstractOnboardResource {
  async getCovenants({ modalityId }) {
    try {
      const response = await this.api.get({
        route: "/public/covenants",
        params: {
          modality_id: modalityId,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response || [],
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: [],
          responseObject: e,
        },
      });
    }
  }

  async find({ covenantId }) {
    try {
      const response = await this.api.get({
        route: `/public/covenants/${covenantId}`,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response || {},
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: {},
          responseObject: e,
        },
      });
    }
  }

  async getAssociatedRoles({ covenantId }) {
    try {
      const response = await this.api.get({
        route: "/public/covenant-roles",
        params: {
          covenant_id: covenantId,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response || {},
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: {},
          responseObject: e,
        },
      });
    }
  }
}
