import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import {
  ModuleRuleDefinitionDataResponseDTO,
  ModuleRuleDefinitionResponseDTO,
} from "@types/resources/onboard/ModuleRuleDefinition";

export default class ModalRuleDefinitionResource extends AbstractOnboardResource {
  async rules({ proposal_id, modal_code }: { proposal_id: string | number; modal_code: string }) {
    try {
      const httpResponse = await this.api.get({
        route: "/modal-rule-definitions",
        params: {
          proposal_id,
          modal_code,
        },
      });

      return this.resourceReturnFactory.create<ModuleRuleDefinitionResponseDTO[]>({
        object: {
          error: false,
          data: httpResponse?.data?.response,
          responseObject: httpResponse,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create<ModuleRuleDefinitionResponseDTO[]>({
        object: {
          error: true,
          data: [],
          responseObject: e,
        },
      });
    }
  }

  async data({
    rule_definition_id,
    value = false,
  }: {
    rule_definition_id: string | number;
    value: boolean;
  }) {
    try {
      const httpResponse = await this.api.get({
        route: "/modal-rule-definitions/data",
        params: {
          rule_definition_id,
          value,
        },
      });

      return this.resourceReturnFactory.create<ModuleRuleDefinitionDataResponseDTO>({
        object: {
          error: false,
          data: httpResponse?.data?.response,
          responseObject: httpResponse,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create<ModuleRuleDefinitionDataResponseDTO>({
        object: {
          error: true,
          data: [],
          responseObject: e,
        },
      });
    }
  }
}
