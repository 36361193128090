import { ActionContext } from "vuex";
import store, { RootState } from "@/store";
import { BankLoanConfigState } from "@/store/modules/bankLoanConfig/state";
import OnboardApiError from "@/errors/OnboardApiError";
import { whiteLabelHandler } from "@/main";

// usado para testar quando há combinações de conf bancárias
// const fakeApiData = [
//   {
//     id: 330,
//     // modality: { code: "onboarding", name: "Onboarding" },
//     modality: { code: "modality_fgts", name: "FGTS" },
//     role: { code: "clt", name: "CLT" },
//     loan_type: { code: "refinanciamento", name: "Refinanciamento" },
//   },
//   {
//     id: 420,
//     modality: { code: "onboarding", name: "Onboarding" },
//     role: { code: "clt", name: "CLT" },
//     loan_type: { code: "refinanciamento", name: "Refinanciamento" },
//   },
//   {
//     id: 540,
//     modality: { code: "onboarding", name: "Onboarding" },
//     // role: { code: "clt", name: "CLT" },
//     role: { code: "outros", name: "Outros" },
//     loan_type: { code: "novo_emprestimo", name: "Novo empréstimo" },
//     // loanType: { code: "refinanciamento", name: "Refinanciamento" },
//   },
//   {
//     id: 620,
//     modality: { code: "onboarding", name: "Onboarding" },
//     role: { code: "clt", name: "CLT" },
//     loan_type: { code: "novo_emprestimo", name: "Novo empréstimo" },
//     // loanType: { code: "refinanciamento", name: "Refinanciamento" },
//   },
// ];

const actions = {
  async searchByBankLoanConfig({
    commit,
    rootState,
  }: ActionContext<BankLoanConfigState, RootState>) {
    const { onboardApi } = rootState.OnboardApi;

    const brand = await whiteLabelHandler.getBrand();

    // verifica se existe id, se não houver vai usar o fluxo antigo criado no frontend (caso do fluxo da dinheirow)
    if (!brand?.id) {
      commit("setBankLoanConfig", []);
      return;
    }

    const { error, data, responseObject } =
      await onboardApi.resources.bankLoanConfiguration.getBankLoanConfigurations(
        brand?.id as number
      );

    if (error) {
      commit("setBankLoanConfig", []);
      throw new OnboardApiError(responseObject);
    } else {
      commit("setBankLoanConfig", data);
    }
  },
  generateAutoBankingConfig({ commit, state }: ActionContext<BankLoanConfigState, RootState>) {
    try {
      commit("generateAutoBankingConfigByProperty", { property: "modality" });
      commit("generateAutoBankingConfigByProperty", { property: "role" });
      commit("generateAutoBankingConfigByProperty", { property: "loan_type" });

      const storageCardsValues = Object.values(state.storageCards);
      // diz se  modality, role e loan_type ja possuem code definido
      const allBankLoanItemDefined =
        storageCardsValues.length === 3
          ? storageCardsValues.length > 0 &&
            storageCardsValues.every((item) => !!item?.code?.length)
          : false;

      /* caso não haja telas possiveis que tenha modality, role e loan_type
       e que todas essas propriedades ja possuem um code quer dizer que a configuração
       bancária foi criada automaticamente */
      if (allBankLoanItemDefined) commit("setAutomaticBankLoanConfig", true);
      else commit("setAutomaticBankLoanConfig", false);
    } catch (e) {}
  },
  async callBankLoanConfig({ dispatch }: ActionContext<any, any>) {
    await dispatch("searchByBankLoanConfig");
    await dispatch("generateAutoBankingConfig");
    await store.dispatch("StatusGroup/updateStatusGroupByBankLoanConfiguration");
  },
};

export default actions;
