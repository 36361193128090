import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export type InterestPlan = {
  id: number;
  data: {
    tableName: string;
    lack: {
      max: number;
      min: number;
      restrict_day: number;
    };
    plan: {
      rate: number;
      installment: number;
    }[];
    term: {
      final: string;
      initial: string;
    };
    initial_term: string;
  };
};

type InterestPlanResponse = {
  show: boolean;
  result: InterestPlan[];
};

export default class InterestPlansResource extends AbstractOnboardResource {
  async getAll(bankLoanConfigId: number): Promise<InterestPlanResponse> {
    try {
      const response = await this.api.get({
        route: "/public/trpc/interestPlans.getAll",
        params: {
          input: { "0": { configId: bankLoanConfigId } },
          batch: 1,
        },
      });

      return response.data[0].result.data.response;
    } catch (e) {
      console.error(e);

      return {
        show: false,
        result: [],
      };
    }
  }
}
