import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

import { AxiosResponse } from "axios";

interface BankLoanConfiguration {
  active: boolean;
  bank_id: number;
  brand_id: number;
  covenant_id: number;
  cr_percentage: number;
  credit_cut_percentage: number;
  cut_day: number | null;
  delay_month: number | null;
  due_day: number;
  id: number;
  iof_type: number;
  loan_category: {
    code: string;
    id: number;
    name: string;
  };
  loan_type: {
    code: string;
    description: string | null;
    help_text: string | null;
    id: number;
    name: string;
    order: number;
  };
  modality: {
    code: string;
    description: string | null;
    help_text: string | null;
    id: number;
    loan_feature_id: number;
    name: string;
    ordering: number | null;
  };
  ocr_type: {
    cnh: string;
    rg: string;
    selfie: string;
  };
  role: {
    code: string;
    description: string | null;
    help_text: string | null;
    id: number;
    name: string;
    ordering: number;
  };
  role_id: number;
  role_subtype_id: number | null;
  score_minimum: number;
  should_send_messages: boolean;
  template_for: string | null;
  use_automatic_approvation: boolean;
  use_block_list: boolean;
  use_pending: boolean;
  use_presumed_income: boolean;
}

interface ResourceReturnModel {
  error: boolean;
  data: BankLoanConfiguration[] | any;
  responseObject: AxiosResponse<any, any> | any;
}

type FindParams = {
  proposalId: string | number;
  includes?: string;
};

export default class BankLoanConfigurationResource extends AbstractOnboardResource {
  async find({ proposalId, includes }: FindParams): Promise<ResourceReturnModel> {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/bank-loan-configuration`,
        params: { includes },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async getBankLoanConfigurations(brandId: string | number): Promise<{
    data: BankLoanConfiguration[];
    response: AxiosResponse<any, any>;
    error: boolean;
  }> {
    try {
      const response = await this.api.get({ route: `brands/${brandId}/bank-loan-configurations` });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response,
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
        },
      });
    }
  }
}
