import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class RefinancingContractResource extends AbstractOnboardResource {
  /**
   *
   * @param proposalId
   * @param lat
   * @param long
   * @return {Promise<ResourceReturnModel>}
   */
  async signContract({ proposalId, lat, long }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/refinancing-contract/sign`,
        payload: { authorize_consignment: true, lat, long },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }
}
