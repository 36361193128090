import ResourceReturnFactory from "@/models/factories/ResourceReturnFactory";
import NewResourceReturnFactory from "@/models/factories/NewResourceReturnFactory";
import AbstractApi from "@/services/api/AbstractApi";

export default class AbstractResource {
  protected resourceReturnFactory: typeof ResourceReturnFactory;
  protected newResourceReturnFactory: typeof NewResourceReturnFactory;
  protected api: AbstractApi;

  protected constructor() {
    this.api = {} as AbstractApi;
    this.resourceReturnFactory = ResourceReturnFactory;
    this.newResourceReturnFactory = NewResourceReturnFactory;
  }
}
