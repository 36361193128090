import AbstractUrlHandler from "./AbstractUrlHandler";

class PageNotFoundHandler extends AbstractUrlHandler {
  constructor() {
    super();
    this.url = "/exit";
  }

  accepts({ to, from }) {
    return to.path === this.url;
  }

  process({ next }) {
    localStorage.removeItem("Authorization");
    next("/");
  }
}

export default new PageNotFoundHandler();
