import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { DefaultHeaders, HttpClient } from "@/arch/@core/gateway/HttpClient";
import store from "@/store";

class HttpClientAdapter implements HttpClient {
  private static httpClientAdapter: HttpClientAdapter;
  readonly httpClientInstance: AxiosInstance;
  private constructor(private config?: HttpClient.Config) {
    this.httpClientInstance = axios.create({
      ...config,
    });
    this.initializeInterceptors();
  }

  static create(config?: HttpClient.Config) {
    if (!this.httpClientAdapter) {
      this.httpClientAdapter = new HttpClientAdapter(config);
    }
    return this.httpClientAdapter;
  }

  private async generateAxiosResponse(callAxiosMethod: () => Promise<AxiosResponse>) {
    let axiosResponse: AxiosResponse;
    try {
      axiosResponse = await callAxiosMethod();
    } catch (error) {
      const err = error as AxiosError;
      axiosResponse = err.response as AxiosResponse;
    }
    return {
      status: axiosResponse?.status,
      data: axiosResponse?.data,
    };
  }

  setDefaultHeaders(defaultHeaders: DefaultHeaders) {
    // @ts-ignore
    this.httpClientInstance.defaults.headers.common = defaultHeaders;
  }

  async request(requestData: HttpClient.Request): Promise<HttpClient.Response> {
    return this.generateAxiosResponse(() =>
      this.httpClientInstance.request({
        params: requestData.params,
        url: requestData.url,
        method: requestData.method,
        data: requestData.body,
        headers: requestData.headers,
      })
    );
  }

  async delete(requestData: HttpClient.Request): Promise<HttpClient.Response> {
    return this.generateAxiosResponse(() =>
      this.httpClientInstance.delete(requestData.url, {
        params: requestData.params,
        data: requestData.body,
        headers: requestData.headers,
      })
    );
  }

  async get(requestData: HttpClient.Request): Promise<HttpClient.Response> {
    return this.generateAxiosResponse(() =>
      this.httpClientInstance.get(requestData.url, {
        params: requestData.params,
        data: requestData.body,
        headers: requestData.headers,
      })
    );
  }

  async post(requestData: HttpClient.Request): Promise<HttpClient.Response> {
    return this.generateAxiosResponse(() =>
      this.httpClientInstance.post(requestData.url, requestData.body, {
        params: requestData.params,
        headers: requestData.headers,
      })
    );
  }

  async put(requestData: HttpClient.Request): Promise<HttpClient.Response> {
    return this.generateAxiosResponse(() =>
      this.httpClientInstance.put(requestData.url, requestData.body, {
        params: requestData.params,
        headers: requestData.headers,
      })
    );
  }

  private initializeInterceptors() {
    this.httpClientInstance.interceptors.request.use(
      async (config) => {
        await store.dispatch("isLoading", true);

        const token = localStorage.getItem("Authorization");
        if (token) {
          // @ts-ignore
          config.headers.common.Authorization = token;
        }

        return config;
      },
      async (config) => {
        await store.dispatch("isLoading", false);
        return config;
      }
    );

    this.httpClientInstance.interceptors.response.use(async (response) => {
      await store.dispatch("isLoading", false);
      return response;
    });
  }
}
const httpClientAdapter = HttpClientAdapter.create({
  baseURL: import.meta.env.VITE_VUE_APP_ONBOARD_URL!,
});

export default httpClientAdapter;
