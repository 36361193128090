import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class LoanValueResource extends AbstractOnboardResource {
  async getLoanValues() {
    return this.resourceReturnFactory.create({
      object: { error: false, data: [5000, 10000, 20000, 30000, 50000] }, // todo Pegar por configuração bancária (falar com David / Adan)
    });
  }

  async getLoanInstallmentValues() {
    return this.resourceReturnFactory.create({
      object: { error: false, data: [100, 400, 800, 1000, 1200] },
    });
  }
}
