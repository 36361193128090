import { inject, injectable, TYPES } from "@/arch/@core/main";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { PendencyDataGateway } from "@/arch/@core/application/protocols/PendencyDataGateway";
import { GatewayResponse } from "@/arch/@core/adapter/gateways/protocols/GatewayInterface";

@injectable()
export class PendencyGateway implements PendencyDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}
  getPendencies(proposalId: string | number): Promise<GatewayResponse<any>> {
    return this.httpClient.get({
      url: `/proposals/${proposalId}/pendencies`,
    });
  }

  resolveFieldPendency(
    pendencyId: string | number,
    new_value: string | number
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/resolve-field-pending/${pendencyId}`,
      body: { new_value },
    });
  }

  resolveFilePendency(
    pendencyId: string | number,
    formData: FormData
  ): Promise<GatewayResponse<any>> {
    return this.httpClient.post({
      url: `/resolve-file-pending/${pendencyId}`,
      body: formData,
    });
  }
}
