import { BlufinDataGateway } from "@/arch/new_proposal/blufin/application/protocols/BlufinDataGateway";
import { HttpClient } from "@/arch/@core/gateway/HttpClient";
import { TYPES, inject, injectable } from "@/arch/@core/main";
@injectable()
export class BlufinRepository implements BlufinDataGateway {
  constructor(@inject(TYPES.HttpClient) private httpClient: HttpClient) {}

  getBankAbcOccupations(): Promise<HttpClient.Response> {
    return this.httpClient.get({
      url: "/bank-abc/occupations",
    });
  }

  getCreditLimit(cpf: string): Promise<HttpClient.Response> {
    return this.httpClient.get({
      url: "/blufin/credit-limit",
      params: { cpf },
    });
  }

  getLoanSimulation(dto: { cpf: string; loanValue: number }): Promise<HttpClient.Response> {
    return this.httpClient.get({
      url: "/blufin/simulation",
      params: {
        cpf: dto.cpf,
        loan_value: dto.loanValue,
      },
    });
  }
}
